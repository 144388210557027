/*================== how work section start here ==================*/
.how-work-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    .shape-one {
        position: absolute;
        border-radius: 1311.523px;
        background: var(--shape-one);
        mix-blend-mode: hard-light;
        filter: blur(150px);
        width: 800px;
        height: 900px;
        transform: translate(-50%, -50%);
        z-index: -1;
        bottom: -657px;
        left: 50%;
    }
}
.how-work{
    position: relative;
    margin-left: 80px;
    @include md-screen {
        margin-left: 0;
    }
    li {
        margin-bottom: 70px;
        @include md-screen {
            padding-left: 15px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        @include msm-screen {
            margin-bottom: 50px;
        }
    }
    @include md-screen {
        padding-left: 70px;
    }
    @include xsm-screen {
        padding-left: 60px;
    }
    &__thumb {
        position: absolute;
        left: -71px;
        top: calc(100% + 14px);
        @include msm-screen {
            top: 100%;
            width: 30px;
            left: -64px;
        }
    }
    &__content {
        // margin-bottom: 100px;
        position: relative;
        @include xsm-screen {
            padding-left: 20px;
        }
    }
    &__title {
        margin-bottom: 10px;
        font-weight: 400;
    }
    &__icon {
        position: absolute;
        left: -85px;
        top: 0px;
        background: var(--base-gradient);
        width: 60px;
        height: 60px;
        font-size: 24px;
        text-align: center;
        border-radius: 8px;
        transition: 0.2s linear;
        color: hsl(var(--white));
        display: flex;
        justify-content: center;
        align-items: center;
        @include md-screen {
            left: -90px;
        }
        @include msm-screen {
            width: 50px;
            height: 50px;
            left: -80px;
            font-size: 20px;
        }
        @include xsm-screen {
            left: -70px;
        }
    }
    &__desc {
        max-width: 450px;
        color: hsl(var(--white)/.8);
        @extend .fs-16;
        
    }
}
.how-work li:last-child .how-work__content:last-child .how-work__thumb {
    display: none;
}
// .how-work__content li:last-child {
//     .how-work__thumb {
//         display: none;
//     }
// }
/*================== how work section end here ==================*/
