/*================ contact section css start here ================*/

.contact-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-bottom: 330px;
    .shape-one {
        position: absolute;
        top: 0;
        left: -545px;
        width: 2667.806px;
        height: 1027.913px;
        transform: rotate(127deg);
        flex-shrink: 0;
        border-radius: 2768.806px;
        background: var(--shape-one);
        mix-blend-mode: hard-light;
        filter: blur(206px);
        z-index: -1;
    }
    .shape-two {
        position: absolute;
        top: 289px;
        right: -1081px;
        width: 2479.806px;
        height: 1250.913px;
        transform: rotate(127deg);
        flex-shrink: 0;
        border-radius: 2768.806px;
        background: var(--shape-one);
        mix-blend-mode: hard-light;
        filter: blur(176px);
        z-index: -1;
    }
}
.contact-item {
    &__title {
        font-family: var(--body-font);
        @include lg-screen {
            margin-bottom: 10px;
        }
    }
    &__icon {
        background: var(--base-gradient);
        font-size: 40px;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
        line-height: 1;
        margin-bottom: 24px;
        @include lg-screen {
            margin-bottom: 10px;
        }
    }
    &
    &__link {
        color: hsl(var(--white));
        margin-top: 24px;
        text-decoration: underline;
        @include lg-screen {
            margin-top: 10px;
        }
    }
}
.contact-bottom {
    &__map {
        iframe {
            min-height: 550px;
            border-radius: 12px;
            width: 100%;
        }
    }
    &__form {
        padding: 0 15px;
        &-title {
            margin-bottom: 35px;
        }
        &-desc {
            margin-bottom: 45px;
        }
    }
}

/*================ contact section css end here ================*/
