/*==================== competitions css start here ====================*/

.dashboard-wrapper {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    .dashboard-card {
        height: 100%;
        margin-bottom: 0;
        @include xl-screen {
            padding: 40px;
        }
        @include msm-screen {
            padding: 20px;
        }
    }
}
.competition {
    &__thumb {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        margin: 0 auto;
        overflow: hidden;
    }
    &__title {
        text-align: center;
        max-width: 270px;
        margin-top: 20px;
        font-size: clamp(1rem, 0.769rem + 0.4813vw, 1.3125rem); // Min: 16 - Max-21
        font-weight: 600;
    }
    &__content {
        text-align: center;
        margin-top: 25px;
    }
    &__text {
        margin-bottom: 10px;
    }
    &-card {
        &__timer {
            &-title {
                color: hsl(var(--white)/.6);
                text-align: center;
                margin-bottom: 30px;
            }
        }
    }
    &__bottom {
        margin-top: 30px;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        grid-template-rows: repeat(2,1fr);
        grid-gap: 1rem;
        @include xsm-screen {
            grid-gap:10px;
        }
        & :last-child {
            grid-column: span 2;
        }
    }
}
.prize-pool {
    &__wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        gap: 20px;
        flex-wrap: wrap;
        border-bottom: 1px solid hsl(var(--white)/.1);
        padding-bottom: 18px;
        margin-bottom: 18px;
    }
    &__text {
        color: hsl(var(--white)/.6);
        @include fs-12;
    }
    &__number {
        font-size: clamp(1rem, 0.769rem + 0.4813vw, 1.3125rem); // Min: 16 - Max-21
        font-weight: 700;
        color: hsl(var(--white)/.6);
        margin-top: 8px;
    }
}

.countdown-list {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    &__item {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: hsl(var(--white)/.6);
        font-size: 9px;
        span {
            @include fs-16;
        }
    }
}
/*==================== competitions css end here ====================*/
