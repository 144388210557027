/*=====================counter section css start her===================== */
.counter-up-section {
    position: relative;
    z-index: 2;
    overflow: hidden;
    .section-heading {
        &__desc {
            max-width: 450px;
        }
    }
    .shape-one {
        position: absolute;
        width: 1311.523px;
        height: 1311.523px;
        transform: rotate(240deg);
        background: var(--shape-one);
        z-index: -1;
        mix-blend-mode: hard-light;
        filter: blur(200px);
        top: 0;
        left: 0;
    }
}
    .counterup-item {
        display: flex;
        justify-content: space-between;
        @include msm-screen {
            padding: 0 10px;
        }
        &__content {
            text-align: center;
        }
        &__title {
            font-weight: 800;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
            gap: 4px;
            @include msm-screen {
                font-size: 14px;
            }
        }
        &__text {
            font-weight: 600;
            @include fs-16;
            color: hsl(var(--white));
            @include msm-screen {
                font-size: 14px;
            }
            @include xsm-screen {
                font-size: 12px;
            }
        }
        &__icon {
            img {
                width: 48px;
                height: 48px;
                @include md-screen {
                    width: 35px;
                    height: 35px;
                }
                @include xsm-screen {
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .counterup-wrapper {
            @include sm-screen {
                flex-direction: column;
            }
        }
    }
    .odometer {
        .odometer-inside {
            font-size: clamp(1.5rem, 1.275rem + 1.125vw, 2.625rem); // Min: 24px - Max: 42px
            line-height: 1;
            @include msm-screen {
                font-size: 16px;
            }
            @include xsm-screen {
                font-size: 14px;
            }
        }
    }

    .odometer .odometer-value, .odometer .odometer-formatting-mark{
     background: var(--base-gradient) !important;
            -webkit-background-clip: text !important;
            -moz-background-clip: text;
            -webkit-text-fill-color: transparent !important; 
            -moz-text-fill-color: transparent;
    }
    
    /*=====================counter section css end her===================== */
    