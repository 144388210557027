
/* ================================= Social Icon Css Start =========================== */
.social-list {
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    gap: 20px;
    &__item {
        &:last-child {
            margin-right: 0;
        }
    }
    &__link {
        position: relative;
        overflow: hidden;
        transition: .3s;
        cursor: pointer;
        font-size: 20px;
        color: hsl(var(--white));
        &.active, &:hover, &:focus  {
            color: hsl(var(--base-two)) !important;
        }
    }
}
/* ================================= Social Icon Css End ===========================  */