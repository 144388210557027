/* ================================= Start Rating Css Start =========================== */
.rating-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;

    &__item {
        color: hsl(var(--white));
        width: 35px;
        height: 35px;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: hsl(var(--base-two));
        &.half {
            position: relative;
            z-index: 1;
            background-color: transparent;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 50%;
                height: 100%;
                z-index: -1;
                background-color: hsl(var(--white)/.7);
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: 100%;
                z-index: -1;
                background-color: hsl(var(--base-two));
            }
        }
    }
    &__text {
        color: hsl(var(--body-color));
    }
}
/* ================================= Start Rating Css End =========================== */