
/* ================================= Common Typography Css Start =========================== */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: var(--body-font);
    color: hsl(var(--white)/.8);
    word-break: break-word;
    background-color: hsl(var(--body-bg));
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    counter-reset: section;
}
p {
    @extend .fs-16; 
    font-weight: 400;
    margin: 0;
}
span {
  display: inline-block;
}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 20px 0;
    font-family: var(--heading-font);
    color: hsl(var(--heading-color));
    line-height: 1.3;
    font-weight: 600;
    @include sm-screen {
      margin: 0 0 15px 0;
    }
  }

  h1 {
    font-size: clamp(1.5rem, 0.3542rem + 2.6474vw, 4.1875rem); // Min: 24px - Max: 67px
    font-weight: 800;
  }
  h2 {
    font-size: clamp(1.5rem, 1.1625rem + 1.6875vw, 3.1875rem); // Min: 24px - Max: 51px
    font-weight: 800;
  }
  h3 {
    font-size: clamp(1.5rem, 1.275rem + 1.125vw, 2.625rem); // Min: 24px - Max: 42px
  }
  h4 {
    font-size: clamp(1.125rem, 1rem + 0.625vw, 1.75rem); // Min: 18px - Max: 28px
  }
  h5 {
    font-size: clamp(1rem, 0.769rem + 0.4813vw, 1.5rem); // Min: 16 - Max-24
  }
  h6 {
    font-size: clamp(1rem, 0.769rem + 0.4813vw, 1.3125rem); // Min: 16 - Max-21
  }
  
  h1 > a,
  h2 > a,
  h3 > a,
  h4 > a,
  h5 > a,
  h6 > a {
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    transition: .2s linear;
    line-height: 1.3;
  }

a {
    display: inline-block;
    transition: .2s linear;
    text-decoration: none;
    color: #2cbece;
}
a:hover {
  color: #2cbece;
}
img {
    max-width: 100%;
    height: auto;
}
select {
  cursor: pointer;
}
ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
button {
  border: 0;
  background-color: transparent;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.form-select:focus {
  outline: 0;
  box-shadow: none;
}
/* ================================= Common Typography Css End =========================== */