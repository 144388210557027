/* ================= Slick Arrow & Dots css Start ================ */ 
.slick-initialized.slick-slider {
    .slick-list {
      margin: 0 -10px;
    }
    .slick-track {
        display: flex;
    }
    .slick-slide {
        height: auto;
        padding: 0 10px;
        > div {
            height: 100%;
        }
    }
  }
  
  .slick-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    color: hsl(var(--white));;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: .2s linear; 
    background-color: hsl(var(--base));
    color: hsl(var(--white));;
    font-size: 20px;
    @include md-screen {
      width: 50px;
      height: 50px;
      font-size: 16px;
    }
    @include msm-screen {
      width: 45px;
      height: 45px;
    }
     &:hover {
      border-color: hsl(var(--base));
      background-color: hsl(var(--base-two));
    }
  }
  .slick-next {
    right: -20px;
    @include md-screen {
      right: -10px;
    }
    @include msm-screen {
      right: 10px;
    }
  }
  .slick-prev {
    left: -20px;
    @include md-screen {
      left: -10px;
    }
    @include msm-screen {
      left: 10px;
    }
  }
  
  /* -------------- Slick Slider Arrow Style two ------------  */
  .slick-arrow {
    position: absolute;
    z-index: 1;
    border: none;
    background-color: transparent;
    color: hsl(var(--white));;
    width: 32px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: .4s;
    background-color:  hsl(var(--black));
    color: hsl(var(--white));
    top: -67px;
    @include lg-screen {
      top: -69px;
    }
    @include sm-screen {
      top: auto; 
      bottom: -50px;
      right: 50%;
    }
  }
  .slick-arrow:hover {
    background-color: hsl(var(--black));
    color: hsl(var(--white));;
  }
  .slick-next {
    right: 10px;
    background-color:  hsl(var(--base));
    @include sm-screen {
      top: auto; 
      right: calc(50% + -37px);
  
    } 
  }
  .slick-prev {
    right: 52px;
    @include sm-screen {
      top: auto; 
      right: calc(50% - -5px);
    }
  }
  /* Dots Css Start */ 
  .slick-dots {
      text-align: center;
      padding-top: 20px;
      li {
          display: inline-block;
          button {
              border: 1px solid transparent;
              background-color: hsl(var(--white)/.5);
              color: hsl(var(--white));;
              margin: 0 8px;
              width: 9px;
              height: 9px;
              border-radius: 50%;
              text-indent: -9999px;
              transition: .3s linear;
          }
          &.slick-active button {
              background:var(--base-gradient);
              width: 11px;
              height: 11px;
              border-color: hsl(var(--white));
              border-radius: 50%;
          }
      }
  }
  /* Dots Css End */
  /* ================= Slick Arrow & Dots css Start ================ */ 
  