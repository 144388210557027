/* ================================= Table Css Start =========================== */
/* Table Content Css start */
.customer { 
	@include xxsm-screen {
		display: block;
		text-align: left;
	}
	&__thumb {
		width: 35px;
		height: 35px;
		border-radius: 50%;
		overflow: hidden;
		@include xxsm-screen {
			margin-left: auto;
		}
	}
	&__content {
		width: calc(100% - 35px);
		padding-left: 15px;
		text-align: left;
		@include xsm-screen {
			padding-left: 8px;
		}
		@include xxsm-screen {
			width: 100%;
			padding-left: 0px;
			padding-top: 5px;
		}	
	}
	&__name {
		margin-bottom: 0;
		@extend .fs-13; 
		color: hsl(var(--black)/0.7); 
	}
}
.action-buttons {
	@extend .flex-align; 
	gap: 10px;
	justify-content: flex-end;
}
.action-btn {
	width: 35px;
	height: 35px;
	border-radius: 50%;
}
.edit-btn {
	color: hsl(var(--info));
	background-color: hsl(var(--info) / .08)
}
.delete-btn {
	color: hsl(var(--danger));
	background-color: hsl(var(--danger) / .08); 
}
/* Table Content Css end */

/* Table Css Start */
.table {
	margin: 0;
	border-collapse: collapse;
	border-collapse: separate;
	border-spacing: 0px 0px;
	thead { 
		tr {
			th {
				text-align: center;
				padding: 15px 20px;
				color: hsl(var(--white)/0.8); 
				font-weight: 600;
                border-bottom: 0;
				max-width: 170px;
				@extend .fs-16; 
				border-bottom: 1px solid hsl(var(--white) / .12);
				@include xxl-screen {
					padding: 10px;
				}
				&:not(:first-child) {
					border-left: 0;
				}
				&:first-child {
					text-align: left;
					border-radius: 6px 0 0 0;
				}
				&:last-child {
					border-radius: 0 6px 0 0; 
					text-align: right;
				}
			}
		}
	}
	tbody {
		border: 0 !important;
		tr {
			border-bottom: 1px solid hsl(var(--white) / .12);
			&:last-child {
				border-bottom: 0;
			}
			&:last-child {
				td {
					border-bottom: 1px solid hsl(var(--white) / .12);
				}
			}
			td {
				text-align: center;
				vertical-align: middle;
				padding: 20px 15px;
				border-width: 1px;
                border: 0;
				color: hsl(var(--white)/0.8); 
				font-weight: 500;
				max-width: 170px;
				@extend .fs-13; 
				border-bottom: 1px solid hsl(var(--white) / .12);
				border-right: none !important;
				&::before {
					content: attr(data-label);
					@extend .fs-16; 
					color: hsl(var(--white)); 
					font-weight: 500;
					display: none;
                    width: 45% !important;
                    text-align: left;
				}
				&:first-child {
					text-align: left;
					border-left: 0;
					@extend .fs-14; 
				}
				&:last-child {
					text-align: right;
					border-right: 0;
				}
			}
		}
	}
}

.table--responsive--md {
    @include sm-screen {
        thead {
			display: none;
		}
		tbody {
			tr {
				display: block;
				&:last-child td {
					border-bottom: 0;
				}
				td {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 15px;
					text-align: right;
					padding: 10px 15px;
					border: none;
					border-bottom: 1px solid hsl(var(--white)/.12);  
					max-width: unset;
					&:last-child {
						border: none;
					}
					&:first-child {
						text-align: right;
						border-left: 0;
					}
					&::before {
					    display: block;
					}
				}
			}
		}
    }
	@include sm-screen {
        tbody {
			tr {
				td {
					border: 0;
				}
			}
		}
    }
}
.table--responsive--lg {
    @include md-screen {
        thead {
			display: none;
		}
		tbody {
			tr {
				display: block;
				&:nth-child(even) {
					background-color: hsl(var(--white) / .3);
				}
				&:last-child td {
					border-bottom: 0;
				}
				td {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 15px;
					text-align: right;
					padding: 10px 15px;
					border: none;
					border-bottom: 1px solid hsl(var(--white) / .12);  
					max-width: unset;
					&:last-child {
						border: none;
					}
					&:first-child {
						text-align: right;
						border-left: 0;
					}
					&::before {
					    display: block;
					}
				}
			}
		}
    }
    @include md-screen {
        tbody {
			tr {
				td {
					border: none;
				}
			}
		}
    }
}
.table--responsive--xl {
    @include lg-screen {
        thead {
			display: none;
		}
		tbody {
			tr {
				display: block;
				&:nth-child(even) {
					background-color: hsl(var(--white) / .1);
				}
				td {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 15px;
					text-align: right;
					padding: 10px 15px;
					border: none;
					border-bottom: 1px solid hsl(var(--white) / .12);  
					max-width: unset;
					&:last-child {
						border: none;
					}
					&:first-child {
						text-align: right;
						border-left: 0;
					}
					&::before {
					    display: block;
					}
				}
			}
		}
    }
    
    @include lg-screen {
        tbody {
			tr {
				td {
					border: 0;
				}
			}
		}
    }
}
.table--responsive--xxl {
    @include xl-screen {
        thead {
			display: none;
		}
		tbody {
			tr {
				display: block;
				&:nth-child(even) {
					background-color: hsl(var(--white) / .1);
				}
				&:last-child td {
					border-bottom: 0;
				}
				td {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 15px;
					text-align: right;
					padding: 10px 15px;
					border: none;
					border-bottom: 1px solid hsl(var(--white) / .12);  
					max-width: unset;
					&:last-child {
						border: none;
					}
					&:first-child {
						text-align: right;
						border-left: 0;
					}
					&::before {
					    display: block;
					}
				}
			}
		}
    }
    
    @include xl-screen {
        tbody {
			tr {
				td {
					border: 0;
				}
			}
		}
    }
}
/* ================================= Table Css End =========================== */