/*================ brand section start here ================*/
.brand-section {
    position: relative;
    z-index: 2;
    overflow: hidden;
    .shape-one {
        position: absolute;
        top: 0;
        left: 50%;
        background: linear-gradient(180deg, rgba(0, 194, 255, 0) 0%, rgba(255, 41, 195, 0.2) 100%);
        mix-blend-mode: hard-light;
        filter: blur(100px);
        width: 600px;
        height: 700px;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
}
.brand-logos {
    display: flex;
    justify-content: center;
    gap: 30px 138px;
    flex-wrap: wrap;
    align-items: center;
    @include lg-screen {
        gap: 30px 100px;
    }
    @include msm-screen {
        gap: 30px 70px;
    }
    @include xsm-screen {
        gap: 30px;
    }
}
.brand-title {
    text-align: center;
    margin-bottom: 25px;
}

/*================ brand section end here ================*/
