/*======================== achievement section css start here ========================*/

.achievement-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-bottom: 330px;
    @include msm-screen {
        padding-bottom: 270px;
    }
    .shape-one {
        position: absolute;
        top: -607px;
        left: -434px;
        width: 2768.806px;
        height: 1675.913px;
        transform: rotate(150deg);
        flex-shrink: 0;
        border-radius: 2768.806px;
        background: var(--shape-one);
        mix-blend-mode: hard-light;
        filter: blur(100px);
        z-index: -1;
    }
    .section-heading {
        &__title {
            max-width: 500px;
        }
    }
}
.achievement {
    &-left {
        &__review {
            display: flex;
            align-items: end;
            gap: 30px;
            flex-wrap: wrap;
            .review-title {
                margin-bottom: 0;
                font-family: var(--body-font);
            }
        }
    }
    &-right {
        .content {
            margin-bottom: 55px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__rating-title {
        margin-bottom: 3px;
        text-align: center;
        display: flex;
        align-items: center;
        gap: 3px;
        .icon {
            font-size: 35px;
            color: hsl(var(--base-two));
        }
    }
}
/*======================== achievement section css end here ========================*/
