/*======================= cta section start here =======================*/

.cta-section {
    position: relative;
    z-index: 2;
    margin-top: -200px;
    overflow: hidden;
    .shape-one {
        position: absolute;
        top: -200px;
        right: 150px;
        background: var(--shape-one);
        mix-blend-mode: hard-light;
        filter: blur(400px);
        width: 1311.523px;
        height: 1311.523px;
        transform: rotate(150.883deg);
        flex-shrink: 0;
        z-index: -1;
        @include md-screen {
            top: 0;
            right: 100px;
        }
    }
    .cta {
        background-color: hsl(var(--section-bg)/.8);
        padding: 80px;
        text-align: center;
        border-radius: 12px;
        border: 1px solid hsl(var(--base)/.2);
        @include md-screen {
            padding: 40px;
        }
        @include msm-screen {
            padding: 40px 15px;
        }
        &__btn {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 45px;
        }
    }
}

/*======================= cta section end here =======================*/
