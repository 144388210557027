/* ================================= Tab Css Start =========================== */
.custom--tab {
    border-radius: 6px;
    margin-bottom: 55px !important;
    gap: 16px;
    justify-content: center;
    .nav-item {
        border-bottom: 0;
        .nav-link {
            color: hsl(var(--white)); 
            padding: 16px 30px !important;
            background-color: hsl(var(--white)/.05) !important;
            border-radius: 8px;
            transition: .4s;
            border: 1px solid hsl(var(--white)/.06) !important;
            @include lg-screen {
                padding: 12px 15px !important;
            }
            &.active {
                color: hsl(var(--white)); 
                background: var(--base-gradient) !important;
                border: 0!important;
                &:hover {
                    color: hsl(var(--white)); 
                }
            }
            &:hover {
                color: hsl(var(--base));
            }
        }
    }
    &.tab-two {
        gap: 35px;
        margin-bottom: 80px !important;
        justify-content: flex-start;
        @include msm-screen {
            gap: 20px;
        }
        .nav-item {
            padding: 0px;
            .nav-link {
                color: hsl(var(--white)/.6);
                padding: 0 !important;
                background-color: transparent !important;
                border-radius: 0px;
                transition: .4s;
                border: 0 !important;
                position: relative;
                font-weight: 600;
                @include fs-16;
                @include msm-screen {
                    padding: 0 10px;
                }
                @include xsm-screen {
                    font-size: 14px;
                }
                &::after {
                    position: absolute;
                    content: "";
                    bottom: -10px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background: var(--base-gradient);
                }
    
                &.active {
                    background: var(--base-gradient);
                    -webkit-background-clip: text !important;
                    -moz-background-clip: text;
                    -webkit-text-fill-color: transparent !important; 
                    -moz-text-fill-color: transparent;
                    &.nav-link {
                        &::after {
                            width: 100%;
                        }
                    }
                    &:hover {
                        color: hsl(var(--base)); 
                    }
                }
                &:hover {
                    color: hsl(var(--base));
                }
            }
        }
    }

    @include md-screen {
        margin-bottom: 35px !important;
    }

}
/* ================================= Tab Css End =========================== */
