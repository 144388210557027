/*============ evaluation css start here ============*/
.evaluation-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    .shape-one {
        position: absolute;
        background: var(--shape-one);
        width: 1624.806px;
        height: 673.913px;
        transform: rotate(138deg);
        z-index: -1;
        mix-blend-mode: hard-light;
        filter: blur(181px);
        border-radius: 2768.806px;
        left: -274px;
        top: 165px;
    }
}

.evaluation-wrapper {
    display: flex;
    flex-wrap: wrap;
    background-color: hsl(var(--white)/.1);
    padding: 20px;
    border-radius: 10px;
    gap: 0;
    margin-top: 40px;
    @include md-screen {
        background-color: transparent;
        border-radius: 0;
        padding: 0;
    }
    &__left {
        width: 280px;
        @include lg-screen {
            width: 250px;
        }
        @include md-screen {
            display: none;
        }
    }
    &__right {
        display: grid;
        grid-template-columns: auto auto auto auto;
        align-items: center;
        width: calc(100% - 280px);
        @include lg-screen {
            width: calc(100% - 250px);
        }
        @include md-screen {
            width: 100%;
            grid-template-columns: auto auto;
            gap: 15px;
        }
        @include msm-screen {
            grid-template-columns: auto;
        }
        .evaluation-card {
            @include md-screen {
                background-color: hsl(var(--section-bg));
                border-radius: 8px;
            }
            &:nth-child(odd) {
                .evaluation-item {
                    background-color: hsl(var(--body-bg)/.25);
                    @include md-screen {
                        background-color: transparent;
                    }
                }
                .evaluation-card__header {
                    background-color: hsl(var(--body-bg)/.25);
                    border-radius: 12px 12px 0 0;
                    @include md-screen {
                        background-color: transparent;
                        border-radius: 0;
                    }
                }
                .evaluation-card__footer {
                    background-color: hsl(var(--body-bg)/.25);
                    border-radius:0 0 12px 12px;
                    @include md-screen {
                        background-color: transparent;
                        border-radius: 0;
                    }
                }
            }
            &:nth-child(even) {
                .evaluation-item {
                    background-color: hsl(var(--white)/.1);
                    @include md-screen {
                        background-color: transparent;
                    }
                }
                .evaluation-card__header {
                    background-color: hsl(var(--white)/.1);
                    border-radius: 12px 12px 0 0;
                    @include md-screen {
                        background-color: transparent;
                        border-radius: 0;
                    }
                }
                .evaluation-card__footer {
                    background-color: hsl(var(--white)/.1);
                    border-radius:0 0 12px 12px;
                    @include md-screen {
                        background-color: transparent;
                        border-radius: 0;
                    }
                }
            }
            &__footer {
                padding: 20px 25px;
                @include lg-screen {
                    padding: 15px;
                }
            }
        }
    }
    .evaluation-item {
        border-bottom: 1px solid hsl(var(--white)/.1);
        padding: 15px 25px;
        @include fs-16;
        font-weight: 500;
        @include lg-screen {
            font-size: 14px;
            padding: 15px;
        }
        @include md-screen {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
        }
        &__name {
            display: none;
            @include md-screen {
                display: inline-block;
            }
        }
    }
    .evaluation-card {
        &__header {
            padding: 20px 25px;
            @include lg-screen {
                padding: 15px;
            }
            @include md-screen {
                text-align: center;
            }
        }
        &__title {
            @include md-screen {
                margin-bottom: 0;
            }
        }
        &__title-two {
            display: none;
            @include md-screen {
                display: block;
            }
        }
    }
}

.select-evaluation {
   .toggle-btn {
    .text {
        @include fs-16;
        font-weight: 500;
    }
   }
}
.text-color {
    background: var(--base-gradient);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
}
.toggle {
    position: relative;
    width: 60px;
    height: 33px;
    border-radius: 100px;
    background-color: hsl(var(--white));
    overflow: hidden;
}
.switch {
    position: absolute;
    left: 2px;
    top: 50%;
    bottom: 2px;
    right: 57.5%;
    background-color: #089EF5;
    border-radius: 36px;
    z-index: 1;
    transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: 0s, 0.08s;
    width: 23px;
    height: 23px;
    transform: translateY(-50%);

}
/*============ evaluation css start here ============*/
