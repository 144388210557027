/* =========================== Accordion Css start ============================= */ 
.custom--accordion {
    .accordion-item {
        border: none !important;
        background-color: transparent !important;
        border-radius: 0;
        overflow: hidden;
        &:not(:last-child) {
            margin-bottom:20px;
        }
    }
    .accordion-header {
        line-height: 1;
    }
    .accordion-body {
        padding: 20px 0;
        background-color: transparent;
        .text {
            max-width: 80%;
            color: hsl(var(--white)/.8);
            @include msm-screen {
                max-width: 100%;
            }
        }
    }
    &:first-of-type .accordion-button.collapsed {
        border-radius: 0;  
    }
    &:last-of-type .accordion-button.collapsed {
        border-radius: 0;  
    }
    .accordion-button{
        background-color: transparent;
        color: var(--heading-color);
        font-size: clamp(1rem, 0.769rem + 0.4813vw, 1.3125rem); // Min: 16 - Max-21
        font-family: var(--body-font);
        padding: 15px 0;
        @include msm-screen {
            padding: 13px 0;
            padding-right: 30px;
            font-size: 17px !important;
        }
        &::after {
            background-image: none;
        }
        &:focus {
            box-shadow: none;
        }
        &:not(.collapsed) {
            color: hsl(var(--white)); 
            background-color: transparent !important;
            box-shadow: none;
            &::after {
                background-image: none;
                color: hsl(var(--base)); ; 
            }
        }
        &[aria-expanded="true"], &[aria-expanded="false"] {
            &::after {
                font-family: "Line Awesome Free";
                font-weight: 900;
                content: "\f00d";
                display: inline-block;
                position: absolute;
                @extend .top-center-extend; 
                right: 15px;
                font-size: 25px;
                height: unset;
            }
        }
        &[aria-expanded="false"] {
            &::after {
                content: "\f067";
                color: hsl(var(--body-color));
            }
        }
    }
}
/* ================================= Accordion Css End =========================== */