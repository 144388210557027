
/* ============= Header Start Here ======================= */
.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    &.logo{
        img {
            max-width: 250px;
            max-height: 60px;
            @include md-screen {
                max-width: 150px;
            }
        }
    }
}

.header {
    position: relative;
    z-index: 5;
    background-color: hsl(var(--body-bg));
    backdrop-filter: blur(100px);
    @include md-screen {
        top: 0px;
        background-color: hsl(var(--body-bg));
        padding: 10px 0;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 999;
        max-height: 101vh;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 0px;
        }
    }
    &.fixed-header {
        background-color: hsl(var(--body-bg));
        position: sticky;
        transition: .3s linear;
        top: 0px;
        animation: slide-down .8s;
        width: 100%;
        border-bottom: 1px solid hsl(var(--white)/.1);
    }
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY( -150%);
    } 
    100% {
        opacity: 1;
        transform: translateY(0);
    } 
}

.navbar {
    padding: 0 !important;
}
/* ========================= Desktop Device Start ========================= */
.header {
    @media (min-width: 992px) {
        .nav-menu {
            padding-top: 0;
            padding-bottom: 0;
            .nav-item{
                position: relative;
                padding-right: 40px;
                @media (max-width:1199px) {
                  padding-right:30px;
                }
                &:last-child {
                    padding-right: 0;
                }
                &.active {
                    .nav-link {
                        color: hsl(var(--base)) !important;
                        &::before {
                            width: 100%;
                            transform: translateX(0); 
                        }
                    }
                }
                &:hover {
                    .nav-link {
                        color: hsl(var(--base)) !important;
                        &::before {
                            width: 100%;
                            transform: translateX(0);
                        }
                        .nav-item__icon {
                            transform: rotate(180deg);
                            transition: .2s;
                        }
                    }
                }
                .nav-link {
                    font-weight: 400;
                    @include fs-16; 
                    color: hsl(var(--white)) !important;
                    padding: 28px 0;
                    position: relative;
                    cursor: pointer;
                    /* ======================== Style two ================ */
                    &:hover::before {
                        left: 0;
                        transition: .3s;
                    }
                    &::before {
                        position: absolute;
                        content: "";
                        right: 0;
                        bottom: 25px;
                        width: 0;
                        height: 2px;
                        background-color: hsl(var(--base)); 
                        transform: translateX(-50%);
                        transition: .3s;
                    }
                    .nav-item__icon{
                        transition: .3s;
                        @include fs-13; 
                        margin-left: 2px;
                        @include md-screen {
                            margin-right: 6px;
                        }
                    }
                }
            }
        }
        .dropdown-menu {
            display: block;
            visibility: hidden;
            opacity: 0;
            transition: .3s;
            top: 100%;
            left: 0;
            padding: 0 !important;
            transform: scaleY(0);
            transform-origin: top center;
            transition: .3s;
            overflow: hidden;
            border-radius: 0;
            min-width: 190px;
            border: 0;
            box-shadow: var(--box-shadow);
            &__link{
                padding: 7px 20px;
                font-weight: 500;
                @include fs-16; 
                transition: .3s;
                &:focus, &:hover {
                    color: hsl(var(--white));
                    background-color: hsl(var(--base)); 
        
                } 
            }
        }
        .nav-menu {
            .nav-item {
                &:hover { 
                    .dropdown-menu{
                        visibility: visible; 
                        opacity: 1;
                        top: 100% !important;
                        transform: scaleY(1);
                    }
                }
            }
        }
    }
}
/* ========================== Desktop Device End ========================= */

/* ============================== Small Device ======================= */
.header {
    @include md-screen {       
        .nav-menu {
            margin-top: 20px;
            .nav-item {
                text-align: left;
                display: block;
                position: relative;
                margin: 0;
                &:hover .nav-link .nav-item__icon {
                    transform: rotate(0deg) !important;
                }
            }
        }
        .nav-item {
            &:first-child{
                border-bottom: none;
            }
            &:last-child > a{
                border-bottom: 0;
            }
            &.active {
                .nav-link {
                    color: hsl(var(--base));
                }
            }
            .nav-link {
                margin-bottom: 8px;
                padding: 10px 10px 10px 0 !important;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0 !important;
                border-bottom: 1px solid hsl(var(--white) / .2);
                color: hsl(var(--white));
                &::before{
                    display: none;
                }
                &.show {
                    &[aria-expanded="true"] {
                        color: hsl(var(--base)) !important;
                        i{
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
        .dropdown-menu {
            box-shadow: none;
            border-radius: 2px;
            width: 100%;
            margin: 0px !important;
            padding: 0 !important;
            border: 0;
            background-color: inherit;
            overflow: hidden;
            li{
                  &:nth-last-child(1){
                    border-bottom: none; 
                }
                .dropdown-item{
                    padding: 10px 0px;
                    font-weight: 500;
                    @include fs-16; 
                    color: hsl(var(--white));
                    border-bottom: 1px solid hsl(var(--white) / .2);
                    margin-left: 20px;
                    color: hsl(var(--white));
                    &:hover, &:focus {
                        background-color: transparent;
                    }
                }
            }
        } 
    }
    
    .navbar-toggler {
        &.header-button {
            border-color: transparent;
            color: hsl(var(--white));
            background: transparent !important;
            padding: 0 !important;
            border: 0 !important;
            border-radius: 0 !important; 
            transition: .15s ease-in-out;
            width: auto;
            font-size: 2.5rem;
    
            &:focus {
            box-shadow: none !important;
            }
            &[aria-expanded="true"] i::before{
                content: "\f00d";
            }
        }
    }
}
@include md-screen {
    .body-overlay.show {
        visibility: visible;
        opacity: 1;
    }
}

.language-box {
    .select {
        color: hsl(var(--white) / .6) !important;
        &:focus {
            color: hsl(var(--white)) !important;
        }
    }

}
/* ================================= Header Css End =========================== */
.custom--dropdown {
    margin-right: 30px;
}
/* Custom Dropdown Css Start */
@media screen and (max-width: 991px) {
    .custom--dropdown {
        width: 85px;
    }
}

.custom--dropdown.open>.custom--dropdown__selected::before {
    transform: rotate(180deg);
}

.custom--dropdown>.custom--dropdown__selected {
    background-color: transparent;
    position: relative;
    cursor: pointer;
    padding: 10px;
    font-size: 14px;
    padding-right: 30px;
}

.custom--dropdown>.custom--dropdown__selected::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    content: "\f107";
    display: inline-block;
    position: absolute;
    margin-left: auto;
    width: unset !important;
    right: 10px;
    color: hsl(var(--white));
    font-size: 12px;
}

@media screen and (max-width: 1399px) {
    .custom--dropdown>.custom--dropdown__selected::before {
        right: 2px;
    }
}

@media screen and (max-width: 1399px) {
    .custom--dropdown>.custom--dropdown__selected {
        padding-right: 15px;
    }
}

.custom--dropdown>.dropdown-list {
    position: absolute;
    background-color: hsl(var(--black));
    width: 100%;
    border-radius: 3px;
    -webkit-box-shadow: 0px 12px 24px rgba(21, 18, 51, 0.13);
    box-shadow: 0px 12px 24px rgba(21, 18, 51, 0.13);
    opacity: 0;
    overflow: hidden;
    transition: 0.25s ease-in-out;
    transform: scaleY(0);
    -webkit-transform-origin: top center;
    transform-origin: top center;
    top: 100%;
    margin-top: 5px;
    z-index: -1;
    visibility: hidden;
    max-height: 230px;
    width: 85px;
    overflow-y: auto !important;
}

@media screen and (max-width: 991px) {
    .custom--dropdown>.dropdown-list {
        left: auto;
        right: 0;
        width: 80px;
    }
}

.custom--dropdown>.dropdown-list::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.custom--dropdown>.dropdown-list::-webkit-scrollbar-thumb {
    background-color: hsl(var(--black)/0.15);
}

.custom--dropdown>.dropdown-list::-webkit-scrollbar-thumb {
    background-color: hsl(var(--black)/0.3);
}

.custom--dropdown.open>.dropdown-list {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    z-index: 999 !important;
    background-color: hsl(var(--base)) !important;
    box-shadow: var(--box-shadow);
}

.dropdown-list>.dropdown-list__item {
    padding: 10px 8px !important;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-size: 14px;
}

.dropdown-list>.dropdown-list__item:hover {
    background-color: hsl(var(--base-two)) !important;
}

.dropdown-list>.dropdown-list__item,
.custom--dropdown>.custom--dropdown__selected {
    display: flex;
    align-items: center;
}

.dropdown-list>.dropdown-list__item .thumb img,
.custom--dropdown>.custom--dropdown__selected .thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
}

.dropdown-list>.dropdown-list__item .thumb img,
.custom--dropdown>.custom--dropdown__selected .thumb img {
    min-width: 24px !important;
    min-height: 24px !important;
    max-width: 24px !important;
    max-height: 24px !important;
    overflow: hidden;
    margin-right: 5px;
    border-radius: 50%;
}

.dropdown-list>.dropdown-list__item .text,
.custom--dropdown>.custom--dropdown__selected .text {
    width: calc(100% - 24px);
    padding-left: 4px;
    color: hsl(var(--white)) !important;
}

// .custom--dropdown__selected .text {
//     display: none;
// }
.dropdown-list__item .thumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
