/* ================================= Custom Classes Css Start =========================== */
/* Column Extra Small Screen */
.col-xsm-6 {
    @media screen and (min-width: 425px) and (max-width: 575px) {
        width: 50%;
    }
}
/* Section Background */
.section-bg-two {
    background-color: hsl(var(--section-bg-two)/.5);
    backdrop-filter: blur(50px);
}

/* Bg Image Css */
.bg-img {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    width: 100%;
    height: 100%;
}

/* Hide Scroll bar Css For Custom Modal */
.scroll-hide {
    position: absolute;
    overflow-y: hidden;
    padding-right: 17px;
    top: 0;
    left: 0;
    width: 100%;
    @include md-screen {
        padding-right: 0;
    }
}
.scroll-hide-sm {
    position: absolute;
    overflow-y: hidden;
    top: 0;
    left: 0;
    width: calc(100% - 0px);
}
/* Overlay Start */
.body-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    content: "";
    left: 0;
    top: 0;
    background-color: hsl(var(--black) / .6);
    z-index: 99;
    transition: .2s linear;
    visibility: hidden;
    opacity: 0;
    &.show-overlay {
        visibility: visible;
        opacity: 1;
    }
}

.sidebar-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    content: "";
    left: 0;
    top: 0;
    background-color: hsl(var(--black) / .5);
    z-index: 99;
    transition: .2s linear;
    visibility: hidden;
    opacity: 0;
    &.show {
        visibility: visible;
        opacity: 1;
        z-index: 999;
    }
}
/* Overlay End */

/* ================================= Custom Classes Css End =========================== */