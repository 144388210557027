/* ================================= Color Css Start =========================== */
.text {
    &--base {
        color: var(--base-gradient) !important;
    }
    &--primary {
        color: hsl(var(--primary)) !important;;
    }
    &--secondary {
        color: hsl(var(--secondary)) !important;;
    }
    &--success {
        color: hsl(var(--success)) !important;;
    }
    &--danger {
        color: hsl(var(--danger)) !important;;
    }
    &--warning {
        color: hsl(var(--warning)) !important;;
    }
    &--info {
        color: hsl(var(--info)) !important;;
    }
    &--dark {
        color: hsl(var(--dark)) !important;;
    }
}
/* ================================= Color Css End =========================== */