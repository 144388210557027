/*===================== faq section start here =====================*/

.faq-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    .shape-one {
        position: absolute;
        left: -128px;
        top: -149px;
        background: var(--shape-one);
        width: 1311.523px;
        height: 1311.523px;
        transform: rotate(-158.666deg);
        flex-shrink: 0;
        z-index: -1;
        mix-blend-mode: hard-light;
        filter: blur(100px);
        border-radius: 1311.523px;
    }
}


/*===================== faq section end here =====================*/
