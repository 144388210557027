/* ================================= padding Css Start =========================== */
.py-120 {
  padding-top: 60px;
  padding-bottom: 60px;
  @media (min-width: 576px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media (min-width: 992px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.pt-120 {
    padding-top: 60px;
    @media (min-width: 576px) {
    padding-top: 80px;
  }
    @media (min-width: 992px) {
    padding-top: 120px;
  }
}
.pb-120 {
    padding-bottom: 60px;
    @media (min-width: 576px) {
    padding-bottom: 80px;
  }
    @media (min-width: 992px) {
    padding-bottom: 120px;
  }
}
.py-60 {
    padding-top: 30px;
    padding-bottom: 30px;
    @media (min-width: 576px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
    @media (min-width: 992px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.pt-60 {
    padding-top: 30px;
    @media (min-width: 576px) {
    padding-top: 40px;
  }
    @media (min-width: 992px) {
    padding-top: 60px;
  }
}
  .pb-60 {
    padding-bottom: 30px;
    @media (min-width: 576px) {
    padding-bottom: 40px;
  }
    @media (min-width: 992px) {
    padding-bottom: 60px;
  }
}
/* ================================= padding Css End =========================== */
