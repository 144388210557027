
/* ====================== Section Heading ==================== */
.section-heading {
  text-align: center;
  margin-bottom: 50px;
  @include lg-screen {
    margin-bottom: 40px;
  }
  @include md-screen {
    margin-bottom: 30px;
  }
  &__subtitle {
    font-size: clamp(1rem, 0.769rem + 0.4813vw, 1.3125rem); // Min: 16 - Max-21
    font-weight: 400;
    margin-bottom: 15px;
    color: hsl(var(--white));
  }
  &__title {
    margin-bottom: 15px;
    font-size: clamp(1.5rem, 1.1625rem + 1.6875vw, 3.125rem);
  }
  &__desc {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    font-size: clamp(1rem, 0.769rem + 0.4813vw, 1.5rem); // Min: 16 - Max-24
  }
  &__btn {
    margin-top: 60px;
    @include md-screen {
      margin-top: 30px;
    }
  }
  // Style left
  &.style-left {
    text-align: left;
    .section-heading {
      &__desc {
        margin-left: 0;
      }
    }
  }
}

/* ====================== Section Heading En d==================== */
