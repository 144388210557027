/*================= why choose section start here =================*/

.why-choose-section {
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: hsl(var(--section-bg-two)/.5);
    .section-heading {
        margin-bottom: 100px;
    }
    .shape-one {
        position: absolute;
        background: var(--shape-one);
        mix-blend-mode: hard-light;
        filter: blur(200px);
        width: 800px;
        height: 1075px;
        transform: translate(-50%, -50%);
        z-index: -1;
        left: 50%;
        top: -23px;
    }
    .choose-item {
        border: 1px solid hsl(var(--white)/.1);
        background: transparent;
        padding: 44px 35px;
        border-radius: 12px;
        height: 100%;
        transition: .3s linear;
        position: relative;
        overflow: hidden;
        z-index: 1;
        @include sm-screen {
            padding: 30px 20px;
        }
        &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: hsl(var(--body-bg));
            z-index: -1;
            transition: .3s linear;
        }
        &:hover {
            background: var(--base-gradient-reverse);
            .choose-item {
                &__icon {
                    background:var(--base-gradient);
                    i {
                        background: hsl(var(--white));
                        -webkit-background-clip: text;
                        -moz-background-clip: text;
                        -webkit-text-fill-color:hsl(var(--white));
                        -moz-text-fill-color: hsl(var(--white));
                    }
                }
            }
        }
        &__icon {
            display: flex;
            width: 93px;
            height: 93px;
            padding: 25px;
            justify-content: center;
            align-items: center;
            background: var(--choose-bg);
            font-size: 40px;
            margin-bottom: 35px;
            border-radius: 12px;
            transition: .3s linear;
            @include sm-screen {
                width: 70px;
                height: 70px;
                padding: 15px;
                font-size: 30px;
                margin-bottom: 25px;
            }
            i {
                background: var(--base-gradient);
                -webkit-background-clip: text;
                -moz-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-text-fill-color: transparent;
            }
        }
        &__title {
           font-family: var(--body-font); 
           font-weight: 700;
           color: hsl(var(--white)/.9);
        }
        &__desc {
            @include fs-16;
            font-weight: 500;
            color: hsl(var(--white)/.9);
        }
    }
}
.choose-item-wrapper [class*=col-]:nth-child(3n+2) .choose-item {
    transform: translateY(-50px);
    @include md-screen {
        transform: translate(0);
    }
}
/*================= why choose section start here =================*/
