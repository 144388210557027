/*==================== about section css start here ====================*/

.banner-two-section {
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: hsl(var(--section-bg-two)) !important;
    @include md-screen {
        padding: 155px 0 100px;
    }
}
.about-section {
    overflow: hidden;
    &__content {
        .title {
            margin-bottom: 15px;
        }
        .text {
            font-family: var(--body-font);
            font-weight: 600;
            margin-bottom: 2rem;
            max-width: 530px;
        }
        .desc {
            margin-bottom: 2rem;
            color: hsl(var(--white));
            max-width: 530px;
        }
    }
    .about-thumb {
        position: relative;
        z-index: 1;
        img {
            border-radius: 40px;
            overflow: hidden;
        }
        &::after {
            position: absolute;
            content: "";
            top: -13px;
            right: 4px;
            width: 155px;
            height: 155px;
            background: var(--base-gradient);
            border-radius: 60px;
            z-index: -1;
            @include xl-screen {
                right: -13px;
            }
            @include msm-screen {
                top: -5px;
                right: -5px;
                border-radius: 40px;
            }
            @include xsm-screen {
                display: none;
            }
        }
        &::before {
            position: absolute;
            content: '';
            bottom: -22px;
            left: -20px;
            width: 335px;
            height: 335px;
            border-radius: 80px;
            z-index: -1;
            background: var(--base-gradient);
            @include msm-screen {
                bottom: -10px;
                left: -10px;
                border-radius: 50px;
            }
            @include xsm-screen {
                display: none;
            }
        }
    }
}

/*==================== about section css end here ====================*/
