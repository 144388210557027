/* ================================ Testimonails Section Css Start ============================= */
.testimonial-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-bottom: 300px;
    @include msm-screen {
        padding-bottom: 270px;
    }
    .shape-one {
        position: absolute;
        top:0;
        right: 0;
        background: var(--shape-one);
        mix-blend-mode: hard-light;
        filter: blur(100px);
        width: 1311.523px;
        height: 1311.523px;
        transform: rotate(150.883deg);
        flex-shrink: 0;
        z-index: -1;
    }
}
.testimonials {
    position: relative;
    overflow: hidden;
}

.testimonails-card {
    padding: 0 10px;
    height: 100%; 
}

.testimonial-item {
    position: relative;
    height: 100%; 
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    &__rating-title {
        margin-bottom: 3px;
        align-items: center;
        display: flex;
        gap: 3px;
        font-family: var(--body-font);
        font-weight: 500;
        font-size: 2rem;
        .icon {
            font-size: 35px;
            color: hsl(var(--base-two));
        }
    }
    &__info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
    &__thumb {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        @include xsm-screen {
            width: 45px;
            height: 45px;
        }
    }
    &__details {
        padding-left: 20px;
        @include xsm-screen {
            padding-left: 10px;
        }
    }
    &__name {
        margin-bottom: 0;
        color: hsl(var(--white)/.9);
        font-family: var(--body-font);
    }
    &__designation {
        color: hsl(var(--white));
        @include fs-12;
    }
    &__desc {
        margin-top: 32px;
        font-size: clamp(1rem, 0.769rem + 0.4813vw, 1.3125rem); // Min: 16 - Max-21
        text-align: center;
        font-weight: 400;

        @include xsm-screen {
            margin-top: 10px;
            padding-top: 10px;
        }
    }
}
.testimonial-wrapper {
    &__review {
        justify-content: center;
        display: flex;
        gap: 10px;
        align-items: center;
        flex-wrap: wrap;
        .review__text {
            font-size: 12px;
            font-weight: 600;
            color: hsl(var(--white)/.8);
        }
        .review__title {
            font-size: 15px;
            .icon {
                color:hsl(var(--base-two));
                font-size: 20px;
            }
        }
    }
}

/* ================================ Testimonails Section Css End ============================= */