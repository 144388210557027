/* ================================= Dashboard Css Start =========================== */
  .dashboard {
      position: relative;
      z-index: 1;
      overflow: hidden;
      @include md-screen {
        z-index: unset;
      }
      .shape-one {
        position: absolute;
        top: 75px;
        left: -39px;
        background: var(--shape-one);
        width: 1556.806px;
        height: 771.913px;
        transform: rotate(150deg);
        flex-shrink: 0;
        mix-blend-mode: hard-light;
        filter: blur(100px);
        border-radius: 2768.806px;
        z-index: -1;
      }
      &__right {
          width: 100%;
          margin-left: 320px;
          @include md-screen {
              width: 100%;
              margin-left: 0;
          }
      }
    // ======================= Dashboard Header Start======================= 
    .dropdown {
        display: inline-block !important;
        @include sm-screen {
        }
    }
    .dashboard-header {
        padding: 20px 56px;
        display: flex;
        justify-content: flex-end;
        @include xxl-screen {
          padding: 20px 40px;
        }
        @include xl-screen {
          padding: 20px 32px;
        }
        @include lg-screen {
          padding: 20px 24px;
        }
        @include sm-screen {
          padding:  16px;
        }
        &__inner {
            padding: 15px 0;
            gap: 10px;
            @include msm-screen {
                display: block;
            }
        }  
        &__right {
          gap: 15px;
        }
        .bd-btn {
          padding: 10px 25px;
          background-color: hsl(var(--base)/.1);
          color: hsl(var(--base));
          border-radius: 5px; 
          font-weight: 600;
          @extend .fs-16; 
          &:hover {
            background-color: hsl(var(--base)/.2);
          }
          &:active {
            position: relative;
            top: 2px;
          }
          .icon {
            margin-right: 5px;
    
          }
        }
    }
    /* User Info Css Start */
    .user-info {
      position: relative;
      &__button {
          padding-right: 20px;
          cursor: pointer;
          gap: 15px;
          @extend .flex-align; 
          @include msm-screen {
            gap: 10px;
          }
      }
      .user-info-dropdown {
          border-radius: 4px;
          overflow: hidden;
          transition: 0.25s linear;
          background-color: hsl(var(--white));
          box-shadow: 0px 5px 25px rgb(0 0 0 / 10%);
          width: 200px;
          position: absolute;
          right: 0;
          z-index: 9;
          top: 100%;
          margin-top: 15px;
          padding: 15px;
          transform: scale(.95);
          visibility: hidden;
          opacity: 0;
          &.show {
              visibility: visible;
              opacity: 1;
              transform: scale(1);
          }
          @include md-screen {
              transform: unset !important;
              top: 43px !important;
          }
          &__item{
              &:last-child {
                  .user-info-dropdown__link {
                      border-bottom: 0 !important;
                  }
              }
          }
          &__link {
              padding: 8px 16px !important;
              color: #5d7387 !important;
              margin-left: 0 !important;
              width: 100%;
              font-weight: 600;
              text-align: left;
              @extend .fs-15; 
              border-radius: 5px;
              &.active {
                  background-color: hsl(var(--base));
              }
              &:hover {
                  background-color: hsl(var(--base)/.08);
                  color: hsl(var(--base)) !important;
              }
              .icon {
                  margin-right: 8px;
              }
          }
      }
      &__icon {
          width: 40px;
          height: 40px;
          font-size: 24px;
          color: hsl(var(--white));
          overflow: hidden;
          border-radius: 50%;
          background-color: hsl(var(--body-bg));
          display: flex;
          align-items: center;
          justify-content: center;
          img {
              @extend .fit-image; 
          }
      }
      &__name {
          color: hsl(var(--white));
          font-weight: 400;
          @extend .fs-14; 
          margin-bottom: -4px;
      }
      &__right{
        @extend .flex-align;
        gap: 25px;
        @include msm-screen {
          margin-top: 20px;
          gap: 10px;
        }
        .notification{
          font-size: 20px;          
          i{
            color: hsl(var(--white));
          }
          &-link{
            position: relative;
            &:hover{
              color: hsl(var(--white));
            }
          }
        }
      }
    }
    /* User Info Css End */
 
    // ======================= Dashboard Header End ======================= 
    
    // ========== Dashboard Body Start ===============
    .dashboard-body {
        position: relative;
        padding: 56px;
        min-height: 100vh;
        @include xxl-screen {
          padding: 56px 40px;
        }
        @include xl-screen {
          padding: 56px 32px;
        }
        @include lg-screen {
          padding: 40px 24px;
        }
        @include sm-screen {
          padding: 32px 16px;
        }
        &__bar {
            &-icon {
                color: hsl(var(--body-color));
                font-size: 1.5625rem; //25px
                margin-bottom: 20px;
                cursor: pointer;
            }
        }
        &__desc {
          margin-top: 50px;
          .title {
            color: hsl(var(--white));
            font-weight: 500;
          }
        }
    }
    .dashboard-card {
      border-radius: 30px;
      padding: 40px 50px;
      background-color: hsl(var(--section-bg-two)/.5);
      margin-bottom: 24px;
      height: 100%;
      @include msm-screen {
        padding: 30px 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }

      &__text:not(:last-child) {
        border-bottom: 1px solid hsl(var(--white)/.2);
        padding-bottom: 40px;
        margin-bottom: 40px;
      }
      .title {
        border-bottom: 1px solid hsl(var(--white)/0.1);
        margin-bottom: 0;
        padding-bottom: 20px;
        font-family: var(--body-font);
      }
      &__desc {
        margin-top: 30px;
        &.desc-two {
          max-width: 990px;
        }
      }
      &__table {
        width: 43%;
        @include xxxl-screen {
          width: 50%;
        }
        @include xl-screen {
          width: 100%;
        }
      }
      &__chart {
        width: 57%;
        @include xxxl-screen {
          width: 50%;
        }
        @include xl-screen {
          width: 100%;
        }
      }
      .custom--tab {
        margin-top: 48px;
        border-bottom: 1px solid hsl(var(--white)/.1);
        padding-bottom: 55px;
        border-radius: 0 !important;
      }
    }
    .dashboard-table {
      margin-top: 25px;
      &.dashboard-card {
        @include xxl-screen {
          padding: 33px;
        }
      }
    }
    .dashboard-card-wrapper {
      display: flex;
      gap: 30px;
      @include xl-screen {
        flex-direction: column;
      }
    }

    /*=================== account Card ===================*/

    .account-card-wrapper {
      display: flex;
      gap: 55px;
      @include xl-screen {
        gap: 30px;
      }
      @include sm-screen {
        flex-wrap: wrap;
      }
     }
    .account-card {
        padding: 40px 50px;
        border-radius: 30px;
        background-color: hsl(var(--section-bg-two)/.5);
        transition: .25s ease-in-out;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:hover {
          transform: translateY(-6px);
        }
        @include xl-screen {
            padding: 30px;
        } 
        @include sm-screen {
            width: 100%;
        } 
        &__title {
          border-bottom: 1px solid hsl(var(--white)/.1);
          margin-bottom: 0;
          padding-bottom: 40px;
          background: var(--base-gradient);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        &__desc {
          max-width: 470px;
          font-size: 21px;
          font-weight: 500;
          @include lg-screen {
            font-size: 17px;
          }
        }
        &__bottom {
          margin-top: 30px;
          display: flex;
          align-items: end;
          .btn {
            padding: 20px 50px;
          }
        }
        .body-title {
          padding-top: 40px;
          font-family: var(--body-font);
        }
    }
    .account-system {
      &__list {
        margin-top: 20px;
      }
      &__item {
        font-weight: 500;
        color: hsl(var(--white)/.8);
        margin-bottom: 5px;
      }
      &__icon {
        background: var(--base-gradient);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
        font-size: 20px;
        padding-right: 10px;
      }
    }
    .dashboard-bottom {
      margin-top: 40px;
      &__text { 
        color: hsl(var(--white)/.8);
      }
      &__link {
        color: hsl(var(--white)/.8);
        &:hover {
          color: hsl(var(--base));
        }
      }
      &__btn {
        margin-top: 40px;
      }
    }
    .dashboard-top {
      margin-bottom: 40px;
    }
/*============  balance range css start here ============*/
.balance-range {
  &__list {
    display: flex;
    gap: 25px;
    margin-top: 20px;
    flex-wrap: wrap;
    @include msm-screen {
      gap: 8px;
    }
  }
  &__item {
    padding: 16px 48px;
    border-radius: 12px;
    position: relative;
    z-index: 1;
    background: var(--base-gradient);
    cursor: pointer;
    @include msm-screen {
      padding: 10px 25px;
    }
    &::before {
      position: absolute;
      content: '';
      top: 1px;
      left: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: hsl(var(--section-bg-two));
      border-radius: 12px;
      z-index: -1;
    }
    &.active {
      background: var(--base-gradient);
      &.balance-range__item::before {
        display: none;
      }
    }
  }
}
/*============  balance range css end here ============*/

/*=========== trade wrapper css start here ===========*/
.trade-wrapper {
  margin-top: 30px;
}
.form-check-wrapper {
  margin-top: 30px;
}
/*=========== account system css start here ===========*/
.account-system {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 70px;
  &__title {
    margin-bottom: 20px;
    color: hsl(var(--white)/.8);
  }
}
/*=========== account system css end here ===========*/

/*=========== billing info css start here ===========*/
.billing-info {
  border-top: 1px solid hsl(var(--white)/0.2);
  padding: 30px 0;
  margin: 30px 0;
  border-bottom: 1px solid hsl(var(--white)/.2);
  &__header {
    margin-bottom: 30px;
    &-title {
      font-weight: 500;
      color: hsl(var(--white));
    }
  }
}
/*=========== billing info css end here ===========*/

/*===== account condition list css start here =====*/
.account-condition {
    display: flex;
    flex-direction: column;
    gap: 35px;
  &__list {
    .item {
      background-color: hsl(var(--body-bg));
      border-radius: 12px;
      position: relative;
      z-index: 1;
      margin-top: 20px;
      margin-bottom: 30px;
      border-right: 5px solid hsl(var(--base));
    }
  }
  &__link {
    color: hsl(var(--white)/.8);
    display: block;
    padding: 17px 24px;
  }
}
.discount {
  margin-top: 60px;
  &__title {
    margin-bottom: 20px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 30px;
    @include md-screen {
      flex-wrap: wrap;
    }
    .btn {
      min-width: 130px;
      height: 56px;
    }
  }
}
/*============ profile css start here ============*/

.account {
  &__header {
      &-thumb {
          position: relative;
          width: 80px;
          margin-bottom: 40px;
          img {
              border-radius: 50%;
              width: 80px;
              height: 80px;
              object-fit: cover;
          }
      }
  }

}
.edit {
  position: absolute;
  width: 18px;
  height: 18px;
  display: flex;
  font-size: 10px; 
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: hsl(var(--white));
  right: -3px;
  top: 10px;
  cursor: pointer;
  color: hsl(var(--black));
}

/*============ profile css end here ============*/


/*===== account condition list css end here =====*/

/*========== certificate css start here ==========*/
 
.certificate-process-system {
  background-color: hsl(var(--body-bg)/.9);
  border: 1px solid hsl(var(--white)/.1);
  border-radius: 12px;
  min-height: 190px;
  padding: 20px;
}

/*========== certificate css start here ==========*/


// ========== Dashboard Body End ==============
/* ================================= Dashboard Css End =========================== */
}
