/*=============== highlight section start here ===============*/

.highlight-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    .shape-one {
        background: var(--shape-one);
        mix-blend-mode: hard-light;
        filter: blur(100px);
        width: 600px;
        height: 400px;
        transform: rotate(-158.666deg);
        z-index: -2;
        position: absolute;
        bottom: -298px;
        right: 225px;
    }

    .highlight-item {
        border: 1px solid hsl(var(--border-color));
        border-radius: 12px;
        padding: 35px 55px;
        background-color: hsl(var(--section-bg)/.3);
        height: 100%;
        transition: .2s linear;
        &:hover {
            border-color: hsl(var(--base));
        }
        @include lg-screen {
            padding: 30px;
        }
        &__title {
            font-family: var(--body-font);
            color: hsl(var(--white)/.9);
            max-width: 215px;
            margin-bottom: 24px;
        }
        &__desc {
            font-family: 500;
            color: hsl(var(--white)/.9);
        }
    }
}

/*================ highlight section end here ================*/
