/* =========================================== Account Css Start =========================*/
.account-inner{
    position: relative;
}
.account-form-wrapper {
    background-color: hsl(var(--body-bg));
    padding: 0px 100px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include xl-screen {
        padding: 40px 30px;
    }
    @include md-screen {
        padding: 35px 25px;
    }
    @include sm-screen {
        padding: 30px 80px;
    }
    @include xsm-screen {
        padding: 30px;
    }
}

.account-form  {
    background-color: hsl(var(--section-bg-two)/.7);
    padding: 40px;
    border-radius: 30px;
    margin-top: 50px;
    &__text {
        text-align: center;
        margin-bottom: 40px;
    }
    &__logo {
        img {
            width: 100%;
        }
    }
    &__title {
        text-align: center;
        margin-bottom: 30px;
    }
    &__forgot-password {
        background-color: hsl(var(--section-bg-two)/.7);
        padding: 40px;
        border-radius: 30px;
        margin-top: 50px;
        &-title {
            text-align: center;
            margin-bottom: 35px;
        }
        &-desc {
            margin-bottom: 35px;
            text-align: center;
        }
    }
}
.login-page {
    margin-top: 20px;
    text-align: center;
    &__link {
        transition: .2s;
        &:hover {
            color: hsl(var(--base));
        }
    }
}
.signup-bottom {
    margin-top: 20px;
    &__text {
        text-align: center;
        margin: 0 auto;
        color: hsl(var(--white)/.6);
        max-width: 380px;
    }
    &__link {
        color: hsl(var(--white)/.6);
        text-decoration: underline;
    }
}
.social-login {
    .btn {
        background-color: hsl(var(--body-bg));
        @include fs-16;
        border: 1px solid hsl(var(--section-bg)/.7);
        color: hsl(var(--white)/.6) !important;
        padding: 17px 25px;
        &:hover, &:focus {
            border-color: hsl(var(--base));
        }
    }
    &__icon {
        background: var(--base-gradient);
        margin-right: 6px;
        font-size: 20px;
        -webkit-background-clip:text;
        -webkit-text-fill-color:transparent;
        -moz-text-fill-color:transparent;
    }
}
.other-option {
    position: relative;
    margin-top: 16px;
    font-family: var(--heading-font);
    font-weight: 700;
    text-align: center;
    margin-bottom: 48px;
    &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        width: 45%;
        border: 0.1px solid hsl(var(--white)/0.1);
    }
    &::after {
        position: absolute;
        content: "";
        right: 0;
        top: 50%;
        width: 45%;
        border: 0.1px solid hsl(var(--white)/0.1);
    }
}
.forgot-password {
    margin-top: 20px;
    text-align: center;
    &__link {
        color: hsl(var(--white)/.6);
    }
}



/* ============================================ Account Css End ====================================*/
.account-inner {
    position: relative;
    display: flex;
    &__left {
        width: 40%;
        position: relative;
        @include lg-screen {
            width: 45%;
        }
        @include md-screen {
            width: 50%;
        }
        @include sm-screen {
            width: 100%;
        }
    }
    &__right {
        width: 60%;
        min-height: 100vh;
        @include lg-screen {
            width: 55%;
        }
        @include md-screen {
            width: 50%;
        }
        @include sm-screen {
            display: none;
        }
    }
}

.account-thumb {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
}
.account-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
