
/* ============= Footer Start Here ======================= */
.footer-area {
    margin-top: -200px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    @include md-screen {
        margin-top: -140px;
    }
    &__wrapper {
        padding-top: 330px;
        @include md-screen {
            padding-top: 270px;
        }
        @include msm-screen {
            padding-top: 200px;
        }
    }
    .shape-one {
        position: absolute;
        top: -270px;
        left: 538px;
        background: var(--shape-one);
        mix-blend-mode: hard-light;
        filter: blur(400px);
        width: 1311.523px;
        height: 1311.523px;
        transform: rotate(150.883deg);
        flex-shrink: 0;
        z-index: -1;
    }
}

.footer-item {
    &__logo {
        margin-bottom: 20px;
        a {
            img {
                width: 100%;
                height: 100%;
                max-width: 300px;
                max-height: 70px;
            }
        }
    }
    &__title {
        color: hsl(var(--white));
        margin-bottom: 25px;
        font-size: 16px !important;
        font-family: var(--body-font);
    }
    &__desc {
        max-width:300px;
    }
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid hsl(var(--white) / .3);
        margin-bottom: 18px;
        padding-bottom: 18px;
    }
    .social-list {
        padding-left: 20px;
    }

}
/* Footer List Item */
.footer-menu {
    display: flex;
    flex-direction: column;
    &__item {
        display: block;
        padding-bottom: 5px;
        &:last-child {
            padding-bottom: 0;
        }
    }
    &__link {
        color: hsl(var(--white)/.8);
        font-weight: 300;
        &:hover {
            color: hsl(var(--base));
            text-decoration: underline;
        }
    }
}

/* Footer Contact */
.footer-contact{
    &__title {
        margin-bottom: 7px;
        font-size: 16px;
        font-family: var(--body-font);
    }
    &__desc {
        color: hsl(var(--body-color-three));
        @include fs-12;
        margin-bottom: 30px;
    }
    &__item {
        padding-bottom: 12px;
        &:last-child {
            padding-bottom: 0;
        }
        &-icon {
            width: 15px;
            color: hsl(var(--base));
            font-size: 1.25rem; // 20px
        }
        &-content {
            width: calc(100% - 15px);
            padding-left: 15px;
            color: hsl(var(--white)/.7);
        }
    }
    .form-control {
        &.form--control {
            background-color: hsl(var(--white) / .1) !important;
            padding: 19px ;
            color: hsl(var(--white));
            padding-right: 120px;
            border-radius: 10px;
            &::placeholder {
                color: hsl(var(--white)/.7);
            }
        }
    }
}
.subscribe-btn {
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translateY(-50%);
    border-radius: 10px;
}
/* ============= Footer End Here ======================= */

/* ============= Bottom Footer End Here ======================= */
.bottom-footer {
    background-color: hsl(var(--body-bg));
}
/* =============Bottom Footer End Here ======================= */

