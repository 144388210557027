
/* ====================== Breadcrumb Css Start ==================== */ 
.breadcrumb {
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    padding: 20px 55px;
    padding-bottom: 0;
    @include md-screen {
        padding: 20px;
    }
    &__wrapper {
        text-align: center;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    &__item{
        color: hsl(var(--white)/.6); 
        padding: 0 5px;
        font-weight: 400;
        @extend .fs-16; 
        &-text {
            color: hsl(var(--white)/.6); 
        }
    }
    &__link {
        color: hsl(var(--white)/.6);  
        font-weight: 500;
        &:hover {
            color: hsl(var(--base)); ;
        }
    }
}
/* ====================== Breadcrumb Css End ==================== */
