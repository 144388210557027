/* ================================= Badge Css Start =========================== */
.badge {
    @extend .fs-14; 
    border-radius: 8px;
    padding: 8px 22px;
    font-weight: 500;
    position: relative;
    text-align: center;
    &--base {
        background-color: hsl(var(--base)) !important; 
        color: hsl(var(--white)) !important;
    }
    &--primary {
        background-color: hsl(var(--primary)) !important; 
        color: hsl(var(--white)) !important;
    }
    &--secondary {
        background-color: hsl(var(--secondary)) !important; 
        color: hsl(var(--white)) !important;
    }
    &--success {
        background-color: hsl(var(--success)) !important; 
        color: hsl(var(--white)) !important;
    }
    &--danger {
        background-color: hsl(var(--danger)) !important; 
        color: hsl(var(--hite)) !important;
    }
    &--warning {
        background-color: hsl(var(--warning)) !important; 
        color: hsl(var(--white)) !important;
    }
    &--info {
        background-color: hsl(var(--info)) !important; 
        color: hsl(var(--white)) !important;
    }
    &--dark {
        background-color: hsl(var(--dark)) !important; 
        color: hsl(var(--white)) !important;
    }
}
/* ================================= Badge Css End =========================== */