.toggle, .toggler {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
}
.toggle-btn {
  text-align: center;
}
.toggler {
  color: hsl(var(--white));
  transition: .2s;
  font-weight: 700;
  font-size: clamp(1rem, 0.769rem + 0.4813vw, 1.3125rem); // Min: 16 - Max-21
}

.toggler--is-active {
  background: var(--base-gradient);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}

.b {
  display: block;
}

.toggle {
  position: relative;
  width: 80px;
  height: 35px;
  border-radius: 100px;
  background-color:hsl(var(--white));
  overflow: hidden;
}

.check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
}

.check:checked ~ .switch {
  right: 2px;
  left: 57.5%;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: .08s, 0s;
}

.switch {
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 57.5%;
  background-color: #089EF5;
  border-radius: 36px;
  z-index: 1;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0s, .08s;
}

