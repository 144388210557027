/*========== payout section css start here ==========*/

.payout-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    .shape-one {
        position: absolute;
        top: -15px;
        right: 400px;
        background: var(--shape-one);
        transform: rotate(-158.666deg);
        width: 1052px;
        height: 626px;
        z-index: -1;
        mix-blend-mode: hard-light;
        filter: blur(150px);
    }
    .section-heading {
        margin-bottom: 0;
        &__desc {
            @include fs-16;
        }
    }
}

/*========== payout section css start here ==========*/
