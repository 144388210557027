/* Font Family*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
/* ========================= Css Variables Start ======================== */
  :root {    
    /* Font Family */
    --heading-font: 'Basement Grotesque Exp';
    --body-font: 'Inter', sans-serif;
  
  /* ========================= Color Variables Start =========================== */
     // White Color 
     --white: 0 0% 100%;
     
    //Light Color
    --light-h: 0;
    --light-s: 1%;
    --light-l: 53%;
    --light: var(--light-h) var(--light-s) var(--light-l);
    
    // Black Color  
    --black-h: 0;
    --black-s: 0%;
    --black-l: 0%;
    --black: var(--black-h) var(--black-s) var(--black-l);
    
    --heading-color: var(--white);
    --body-color: var(--white);
    --border-color: 207 44% 32%;
    --body-bg: 208 88% 13%;
    --section-bg: 207 51% 24%;
    --section-bg-two: 207 90% 8%;

   /* ================================ Box Shadow Start =============================== */
    // Header Box shadow
    --header-box-shadow: 0px -1px 15px 3px hsl(var(--black) /.3); 
   // Mobile bottom fix menu box shadow 
    --mobile-box-shadow: 0px -1px 5px 0px hsl(var(--black) /.92);
    // Card box shadow
    --box-shadow: 0px 2px 15px hsl(var(--black) /.05);
    /* ================================ Box Shadow End =============================== */

    /*=========================== gradient color start here ===========================*/
    --base-gradient: linear-gradient(90deg, hsl(var(--base-three)) 0%, hsl(var(--base-two)) 100%, hsl(var(--base)) 47.11%);
    --base-gradient-reverse: linear-gradient(90deg, hsl(var(--base-two)) 0%, hsl(var(--base)) 47.11%, hsl(var(--base-three)) 100%);
    --gradient: linear-gradient(90deg, hsl(var(--base-two)) 0%,  hsl(var(--base-two)) 100%);

    --gradient-two: linear-gradient(180deg, rgba(0, 194, 255, 0.00) 0%, rgba(255, 41, 195, 0.80) 100%);
    --gradient-three: linear-gradient(180deg, rgba(24, 255, 158, 0.00) 0%, #17FFE3 100%);
    --gradient-four: linear-gradient(180deg, rgba(0, 194, 255, 0.00) 0%, rgba(255, 41, 195, 0.20) 100%);
    // --section-gradient: linear-gradient(90deg, hsl(var(--section-bg)) 100%);

    --shape-one: linear-gradient(180deg, rgba(0, 194, 255, 0.00) 0%, rgba(255, 41, 195, 0.20) 100%);

    --choose-bg: linear-gradient(90deg, rgba(8, 158, 245, 0.10) 0%, rgba(44, 188, 205, 0.10) 47.11%, rgba(95, 231, 147, 0.10) 100%);


    /*=========================== gradient color end here ===========================*/

    
    /* ========================= Base Color ============================= */
    --base-h: 186;
    --base-s: 65%;
    --base-l: 49%;

    --base: var(--base-h) var(--base-s) var(--base-l);
    /* Base Darken  */
    --base-d-100: var(--base-h) var(--base-s) calc(var(--base-l) - var(--base-l) * 0.1);
    --base-d-200: var(--base-h) var(--base-s) calc(var(--base-l) - var(--base-l) * 0.2);
    --base-d-300: var(--base-h) var(--base-s) calc(var(--base-l) - var(--base-l) * 0.3);
    --base-d-400: var(--base-h) var(--base-s) calc(var(--base-l) - var(--base-l) * 0.4);
    --base-d-500: var(--base-h) var(--base-s) calc(var(--base-l) - var(--base-l) * 0.5);
    --base-d-600: var(--base-h) var(--base-s) calc(var(--base-l) - var(--base-l) * 0.6);
    --base-d-700: var(--base-h) var(--base-s) calc(var(--base-l) - var(--base-l) * 0.7);
    --base-d-700: var(--base-h) var(--base-s) calc(var(--base-l) - var(--base-l) * 0.7);
    --base-d-800: var(--base-h) var(--base-s) calc(var(--base-l) - var(--base-l) * 0.8);
    --base-d-900: var(--base-h) var(--base-s) calc(var(--base-l) - var(--base-l) * 0.9);

    /* Base Lighten */
    --base-l-100: var(--base-h) calc(var(--base-s)) calc(var(--base-l) + (100% - var(--base-l)) * 0.1);
    --base-l-200: var(--base-h) calc(var(--base-s)) calc(var(--base-l) + (100% - var(--base-l)) * 0.2);
    --base-l-300: var(--base-h) calc(var(--base-s)) calc(var(--base-l) + (100% - var(--base-l)) * 0.3);
    --base-l-400: var(--base-h) calc(var(--base-s)) calc(var(--base-l) + (100% - var(--base-l)) * 0.4);
    --base-l-500: var(--base-h) calc(var(--base-s)) calc(var(--base-l) + (100% - var(--base-l)) * 0.5);
    --base-l-600: var(--base-h) calc(var(--base-s)) calc(var(--base-l) + (100% - var(--base-l)) * 0.6);
    --base-l-700: var(--base-h) calc(var(--base-s)) calc(var(--base-l) + (100% - var(--base-l)) * 0.7);
    --base-l-800: var(--base-h) calc(var(--base-s)) calc(var(--base-l) + (100% - var(--base-l)) * 0.8);
    --base-l-900: var(--base-h) calc(var(--base-s)) calc(var(--base-l) + (100% - var(--base-l)) * 0.9);

    --background-gradient: linear-gradient(45deg, hsl(var( --base)), hsl(var(--base-two)));

       /* ========================= Base Two Color ============================= */
       --base-two-h: 143;
       --base-two-s: 74%;
       --base-two-l: 64%;
       --base-two: var(--base-two-h) var(--base-two-s) var(--base-two-l);
   
         /* Base Two Darken */
        --base-two-d-100: var(--base-two-h) var(--base-two-s) calc(var(--base-two-l) - var(--base-two-l) * 0.05);
        --base-two-d-200: var(--base-two-h) var(--base-two-s) calc(var(--base-two-l) - var(--base-two-l) * 0.1);
        --base-two-d-300: var(--base-two-h) var(--base-two-s) calc(var(--base-two-l) - var(--base-two-l) * 0.2);
        --base-two-d-400: var(--base-two-h) var(--base-two-s) calc(var(--base-two-l) - var(--base-two-l) * 0.3);
        --base-two-d-500: var(--base-two-h) var(--base-two-s) calc(var(--base-two-l) - var(--base-two-l) * 0.4);
        --base-two-d-600: var(--base-two-h) var(--base-two-s) calc(var(--base-two-l) - var(--base-two-l) * 0.5);
        --base-two-d-700: var(--base-two-h) var(--base-two-s) calc(var(--base-two-l) - var(--base-two-l) * 0.6);
        --base-two-d-800: var(--base-two-h) var(--base-two-s) calc(var(--base-two-l) - var(--base-two-l) * 0.7);
        --base-two-d-900: var(--base-two-h) var(--base-two-s) calc(var(--base-two-l) - var(--base-two-l) * 0.8);
    
       /* Base Two Lighten */
        --base-two-l-100: var(--base-two-h) calc(var(--base-two-s)) calc(var(--base-two-l) + (100% - var(--base-two-l)) * 0.05);
        --base-two-l-200: var(--base-two-h) calc(var(--base-two-s)) calc(var(--base-two-l) + (100% - var(--base-two-l)) * 0.1);
        --base-two-l-300: var(--base-two-h) calc(var(--base-two-s)) calc(var(--base-two-l) + (100% - var(--base-two-l)) * 0.2);
        --base-two-l-400: var(--base-two-h) calc(var(--base-two-s)) calc(var(--base-two-l) + (100% - var(--base-two-l)) * 0.3);
        --base-two-l-500: var(--base-two-h) calc(var(--base-two-s)) calc(var(--base-two-l) + (100% - var(--base-two-l)) * 0.4);
        --base-two-l-600: var(--base-two-h) calc(var(--base-two-s)) calc(var(--base-two-l) + (100% - var(--base-two-l)) * 0.5);
        --base-two-l-700: var(--base-two-h) calc(var(--base-two-s)) calc(var(--base-two-l) + (100% - var(--base-two-l)) * 0.6);
        --base-two-l-800: var(--base-two-h) calc(var(--base-two-s)) calc(var(--base-two-l) + (100% - var(--base-two-l)) * 0.7);
        --base-two-l-900: var(--base-two-h) calc(var(--base-two-s)) calc(var(--base-two-l) + (100% - var(--base-two-l)) * 0.8);

    /* ========================= Base Three Color ============================= */
    --base-three-h: 202;
    --base-three-s: 94%;
    --base-three-l: 50%;
    --base-three: var(--base-three-h) var(--base-three-s) var(--base-three-l);

      /* Base Two Darken */
     --base-three-d-100: var(--base-three-h) var(--base-three-s) calc(var(--base-three-l) - var(--base-three-l) * 0.05);
     --base-three-d-200: var(--base-three-h) var(--base-three-s) calc(var(--base-three-l) - var(--base-three-l) * 0.1);
     --base-three-d-300: var(--base-three-h) var(--base-three-s) calc(var(--base-three-l) - var(--base-three-l) * 0.2);
     --base-three-d-400: var(--base-three-h) var(--base-three-s) calc(var(--base-three-l) - var(--base-three-l) * 0.3);
     --base-three-d-500: var(--base-three-h) var(--base-three-s) calc(var(--base-three-l) - var(--base-three-l) * 0.4);
     --base-three-d-600: var(--base-three-h) var(--base-three-s) calc(var(--base-three-l) - var(--base-three-l) * 0.5);
     --base-three-d-700: var(--base-three-h) var(--base-three-s) calc(var(--base-three-l) - var(--base-three-l) * 0.6);
     --base-three-d-800: var(--base-three-h) var(--base-three-s) calc(var(--base-three-l) - var(--base-three-l) * 0.7);
     --base-three-d-900: var(--base-three-h) var(--base-three-s) calc(var(--base-three-l) - var(--base-three-l) * 0.8);
 
    /* Base Two Lighten */
     --base-three-l-100: var(--base-three-h) calc(var(--base-three-s)) calc(var(--base-three-l) + (100% - var(--base-three-l)) * 0.05);
     --base-three-l-200: var(--base-three-h) calc(var(--base-three-s)) calc(var(--base-three-l) + (100% - var(--base-three-l)) * 0.1);
     --base-three-l-300: var(--base-three-h) calc(var(--base-three-s)) calc(var(--base-three-l) + (100% - var(--base-three-l)) * 0.2);
     --base-three-l-400: var(--base-three-h) calc(var(--base-three-s)) calc(var(--base-three-l) + (100% - var(--base-three-l)) * 0.3);
     --base-three-l-500: var(--base-three-h) calc(var(--base-three-s)) calc(var(--base-three-l) + (100% - var(--base-three-l)) * 0.4);
     --base-three-l-600: var(--base-three-h) calc(var(--base-three-s)) calc(var(--base-three-l) + (100% - var(--base-three-l)) * 0.5);
     --base-three-l-700: var(--base-three-h) calc(var(--base-three-s)) calc(var(--base-three-l) + (100% - var(--base-three-l)) * 0.6);
     --base-three-l-800: var(--base-three-h) calc(var(--base-three-s)) calc(var(--base-three-l) + (100% - var(--base-three-l)) * 0.7);
     --base-three-l-900: var(--base-three-h) calc(var(--base-three-s)) calc(var(--base-three-l) + (100% - var(--base-three-l)) * 0.8);

    /* ============================== Bootstrap Modifier Start ============================== */
    /* Primary Color */
    --primary-h: 211;
    --primary-s: 100%;
    --primary-l: 50%;
    --primary: var(--primary-h) var(--primary-s) var(--primary-l);
    /* Primary Darken */ 
    --primary-d-100: var(--primary-h) var(--primary-s) calc(var(--primary-l) - var(--primary-l) * 0.1);
    --primary-d-200: var(--primary-h) var(--primary-s) calc(var(--primary-l) - var(--primary-l) * 0.2);
    --primary-d-300: var(--primary-h) var(--primary-s) calc(var(--primary-l) - var(--primary-l) * 0.3);
    --primary-d-400: var(--primary-h) var(--primary-s) calc(var(--primary-l) - var(--primary-l) * 0.4);
    --primary-d-500: var(--primary-h) var(--primary-s) calc(var(--primary-l) - var(--primary-l) * 0.5);
    /* primary Lighten */
    --primary-l-100: var(--primary-h) calc(var(--primary-s)) calc(var(--primary-l) + (100% - var(--primary-l)) * 0.1);
    --primary-l-200: var(--primary-h) calc(var(--primary-s)) calc(var(--primary-l) + (100% - var(--primary-l)) * 0.2);
    --primary-l-300: var(--primary-h) calc(var(--primary-s)) calc(var(--primary-l) + (100% - var(--primary-l)) * 0.3);
    --primary-l-400: var(--primary-h) calc(var(--primary-s)) calc(var(--primary-l) + (100% - var(--primary-l)) * 0.4);
    --primary-l-500: var(--primary-h) calc(var(--primary-s)) calc(var(--primary-l) + (100% - var(--primary-l)) * 0.5);
    --primary-l-600: var(--primary-h) calc(var(--primary-s)) calc(var(--primary-l) + (100% - var(--primary-l)) * 0.6);

    /* Secondary Color */
    --secondary-h: 208;
    --secondary-s: 7%;
    --secondary-l: 46%;
    --secondary: var(--secondary-h) var(--secondary-s) var(--secondary-l);
    /* Secondary Darken */ 
    --secondary-d-100: var(--secondary-h) var(--secondary-s) calc(var(--secondary-l) - var(--secondary-l) * 0.1);
    --secondary-d-200: var(--secondary-h) var(--secondary-s) calc(var(--secondary-l) - var(--secondary-l) * 0.2);
    --secondary-d-300: var(--secondary-h) var(--secondary-s) calc(var(--secondary-l) - var(--secondary-l) * 0.3);
    --secondary-d-400: var(--secondary-h) var(--secondary-s) calc(var(--secondary-l) - var(--secondary-l) * 0.4);
    --secondary-d-500: var(--secondary-h) var(--secondary-s) calc(var(--secondary-l) - var(--secondary-l) * 0.5);   
    /* secondary Lighten */
    --secondary-l-100: var(--secondary-h) calc(var(--secondary-s)) calc(var(--secondary-l) + (100% - var(--secondary-l)) * 0.1);
    --secondary-l-200: var(--secondary-h) calc(var(--secondary-s)) calc(var(--secondary-l) + (100% - var(--secondary-l)) * 0.2);
    --secondary-l-300: var(--secondary-h) calc(var(--secondary-s)) calc(var(--secondary-l) + (100% - var(--secondary-l)) * 0.3);
    --secondary-l-400: var(--secondary-h) calc(var(--secondary-s)) calc(var(--secondary-l) + (100% - var(--secondary-l)) * 0.4);
    --secondary-l-500: var(--secondary-h) calc(var(--secondary-s)) calc(var(--secondary-l) + (100% - var(--secondary-l)) * 0.5);
    --secondary-l-600: var(--secondary-h) calc(var(--secondary-s)) calc(var(--secondary-l) + (100% - var(--secondary-l)) * 0.6);

    /* Success Color */
    --success-h: 99;
    --success-s: 52%;
    --success-l: 55%;
    --success: var(--success-h) var(--success-s) var(--success-l);
    /* Success Darken */ 
    --success-d-100: var(--success-h) var(--success-s) calc(var(--success-l) - var(--success-l) * 0.1);
    --success-d-200: var(--success-h) var(--success-s) calc(var(--success-l) - var(--success-l) * 0.2);
    --success-d-300: var(--success-h) var(--success-s) calc(var(--success-l) - var(--success-l) * 0.3);
    --success-d-400: var(--success-h) var(--success-s) calc(var(--success-l) - var(--success-l) * 0.4);
    --success-d-500: var(--success-h) var(--success-s) calc(var(--success-l) - var(--success-l) * 0.5);
    /* Success Lighten */
    --success-l-100: var(--success-h) calc(var(--success-s)) calc(var(--success-l) + (100% - var(--success-l)) * 0.1);
    --success-l-200: var(--success-h) calc(var(--success-s)) calc(var(--success-l) + (100% - var(--success-l)) * 0.2);
    --success-l-300: var(--success-h) calc(var(--success-s)) calc(var(--success-l) + (100% - var(--success-l)) * 0.3);
    --success-l-400: var(--success-h) calc(var(--success-s)) calc(var(--success-l) + (100% - var(--success-l)) * 0.4);
    --success-l-500: var(--success-h) calc(var(--success-s)) calc(var(--success-l) + (100% - var(--success-l)) * 0.5);
    --success-l-600: var(--success-h) calc(var(--success-s)) calc(var(--success-l) + (100% - var(--success-l)) * 0.6);

    /* Danger Color */
    --danger-h: 0;
    --danger-s: 96%;
    --danger-l: 63%;
    --danger: var(--danger-h) var(--danger-s) var(--danger-l);
    /* Danger Darken */ 
    --danger-d-100: var(--danger-h) var(--danger-s) calc(var(--danger-l) - var(--danger-l) * 0.1);
    --danger-d-200: var(--danger-h) var(--danger-s) calc(var(--danger-l) - var(--danger-l) * 0.2);
    --danger-d-300: var(--danger-h) var(--danger-s) calc(var(--danger-l) - var(--danger-l) * 0.3);
    --danger-d-400: var(--danger-h) var(--danger-s) calc(var(--danger-l) - var(--danger-l) * 0.4);
    --danger-d-500: var(--danger-h) var(--danger-s) calc(var(--danger-l) - var(--danger-l) * 0.5);
   /* danger Lighten */
    --danger-l-100: var(--danger-h) calc(var(--danger-s)) calc(var(--danger-l) + (100% - var(--danger-l)) * 0.1);
    --danger-l-200: var(--danger-h) calc(var(--danger-s)) calc(var(--danger-l) + (100% - var(--danger-l)) * 0.2);
    --danger-l-300: var(--danger-h) calc(var(--danger-s)) calc(var(--danger-l) + (100% - var(--danger-l)) * 0.3);
    --danger-l-400: var(--danger-h) calc(var(--danger-s)) calc(var(--danger-l) + (100% - var(--danger-l)) * 0.4);
    --danger-l-500: var(--danger-h) calc(var(--danger-s)) calc(var(--danger-l) + (100% - var(--danger-l)) * 0.5);
    --danger-l-600: var(--danger-h) calc(var(--danger-s)) calc(var(--danger-l) + (100% - var(--danger-l)) * 0.6);

    /* Warning Color */
    --warning-h: 29;
    --warning-s: 100%;
    --warning-l: 63%;
    --warning: var(--warning-h) var(--warning-s) var(--warning-l);
    /* Warning Darken */ 
    --warning-d-100: var(--warning-h) var(--warning-s) calc(var(--warning-l) - var(--warning-l) * 0.1);
    --warning-d-200: var(--warning-h) var(--warning-s) calc(var(--warning-l) - var(--warning-l) * 0.2);
    --warning-d-300: var(--warning-h) var(--warning-s) calc(var(--warning-l) - var(--warning-l) * 0.3);
    --warning-d-400: var(--warning-h) var(--warning-s) calc(var(--warning-l) - var(--warning-l) * 0.4);
    --warning-d-500: var(--warning-h) var(--warning-s) calc(var(--warning-l) - var(--warning-l) * 0.5);
    /* Warning Lighten */
    --warning-l-100: var(--warning-h) calc(var(--warning-s)) calc(var(--warning-l) + (100% - var(--warning-l)) * 0.1);
    --warning-l-200: var(--warning-h) calc(var(--warning-s)) calc(var(--warning-l) + (100% - var(--warning-l)) * 0.2);
    --warning-l-300: var(--warning-h) calc(var(--warning-s)) calc(var(--warning-l) + (100% - var(--warning-l)) * 0.3);
    --warning-l-400: var(--warning-h) calc(var(--warning-s)) calc(var(--warning-l) + (100% - var(--warning-l)) * 0.4);
    --warning-l-500: var(--warning-h) calc(var(--warning-s)) calc(var(--warning-l) + (100% - var(--warning-l)) * 0.5);
    --warning-l-600: var(--warning-h) calc(var(--warning-s)) calc(var(--warning-l) + (100% - var(--warning-l)) * 0.6);

    /* Info Color */
    --info-h: 196;
    --info-s: 100%;
    --info-l: 50%;
    --info: var(--info-h) var(--info-s) var(--info-l);
    /* Info Darken */ 
    --info-d-100: var(--info-h) var(--info-s) calc(var(--info-l) - var(--info-l) * 0.1);
    --info-d-200: var(--info-h) var(--info-s) calc(var(--info-l) - var(--info-l) * 0.2);
    --info-d-300: var(--info-h) var(--info-s) calc(var(--info-l) - var(--info-l) * 0.3);
    --info-d-400: var(--info-h) var(--info-s) calc(var(--info-l) - var(--info-l) * 0.4);
    --info-d-500: var(--info-h) var(--info-s) calc(var(--info-l) - var(--info-l) * 0.5);
    // Info Lighten 
    --info-l-100: var(--info-h) calc(var(--info-s)) calc(var(--info-l) + (100% - var(--info-l)) * 0.1);
    --info-l-200: var(--info-h) calc(var(--info-s)) calc(var(--info-l) + (100% - var(--info-l)) * 0.2);
    --info-l-300: var(--info-h) calc(var(--info-s)) calc(var(--info-l) + (100% - var(--info-l)) * 0.3);
    --info-l-400: var(--info-h) calc(var(--info-s)) calc(var(--info-l) + (100% - var(--info-l)) * 0.4);
    --info-l-500: var(--info-h) calc(var(--info-s)) calc(var(--info-l) + (100% - var(--info-l)) * 0.5);
    --info-l-600: var(--info-h) calc(var(--info-s)) calc(var(--info-l) + (100% - var(--info-l)) * 0.6);

    /* dark Color */
    --dark-h: 207;
    --dark-s: 90%;
    --dark-l: 8%;

    --dark: var(--dark-h) var(--dark-s) var(--dark-l);
    /* dark Darken */ 
    --dark-d-100: var(--dark-h) var(--dark-s) calc(var(--dark-l) - var(--dark-l) * 0.1);
    --dark-d-200: var(--dark-h) var(--dark-s) calc(var(--dark-l) - var(--dark-l) * 0.2);
    --dark-d-300: var(--dark-h) var(--dark-s) calc(var(--dark-l) - var(--dark-l) * 0.3);
    --dark-d-400: var(--dark-h) var(--dark-s) calc(var(--dark-l) - var(--dark-l) * 0.4);
    --dark-d-500: var(--dark-h) var(--dark-s) calc(var(--dark-l) - var(--dark-l) * 0.5);
    // dark Lighten
    --dark-l-100: var(--dark-h) calc(var(--dark-s)) calc(var(--dark-l) + (100% - var(--dark-l)) * 0.1);
    --dark-l-200: var(--dark-h) calc(var(--dark-s)) calc(var(--dark-l) + (100% - var(--dark-l)) * 0.2);
    --dark-l-300: var(--dark-h) calc(var(--dark-s)) calc(var(--dark-l) + (100% - var(--dark-l)) * 0.3);
    --dark-l-400: var(--dark-h) calc(var(--dark-s)) calc(var(--dark-l) + (100% - var(--dark-l)) * 0.4);
    --dark-l-500: var(--dark-h) calc(var(--dark-s)) calc(var(--dark-l) + (100% - var(--dark-l)) * 0.5);
    --dark-l-600: var(--dark-h) calc(var(--dark-s)) calc(var(--dark-l) + (100% - var(--dark-l)) * 0.6);
    /* ============================== Bootstrap Modifier End ============================== */
  }
/* ========================= Css Variables End =========================== */