/* =========================== Banner Section Start Here ========================= */
.banner-section {
    position: relative;
    z-index: 1;
    padding: 180px 0 240px;
    overflow: hidden;
    background-color: hsl(var(--section-bg-two)) !important;
    @include md-screen {
        padding: 160px 0 170px;
    }
    &.bg-img {
        @include md-screen {
            background-position: center right;
            object-fit: contain;
        }
    }

}
.banner-content {
    text-align: center;
    &__title {
        color: hsl(var(--white));
        font-weight: 800;
        font-size: clamp(1.75rem, 0.975rem + 3.875vw, 5.625rem); // Min:30px - Max:90px
    }
    &__subtitle {
        font-weight: 800;
        margin-bottom: 0;
    }
    &__desc {
        max-width: 730px;
        margin: 0 auto;
        color: hsl(var(--white));
        font-weight: 600;
        font-size: clamp(1rem, 0.769rem + 0.4813vw, 1.3125rem); // Min: 16 - Max-21
    }
    &__button {
        margin-top: 60px;
        @include xxsm-screen {
            display: block !important;
        }
        .btn {
            @include xxsm-screen {
                width: 100%;
            }
        }
    }
}
.banner-content__button {
    .btn:not(:last-child) {
        @include xxsm-screen {
            margin-bottom: 20px;
        }
    }
}
.title-style {
    background: var(--base-gradient);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
}
/* =========================== Banner Section End Here ========================= */

/* body-gradient bg color css */
.shape-one-bg {
    position: absolute;
    top: 32px;
    right: -440px;
    background: var(--gradient-two);
    width: 694.001px;
    height: 694px;
    filter: blur(113px);
    transform: rotate(186deg);
    z-index: -1;
    opacity: .7;
}
.shape-two-bg {
    position: absolute;
    top: 159px;
    left: -190px;
    background: var(--gradient);
    filter: blur(100px);
    z-index: -2;
    width: 740px;
    height: 508px;
    opacity: 0.2;
    transform: rotate(341deg);
}
.shape-three-bg {
    position: absolute;
    top: -148px;
    left: 40px;
    background-color: hsl(var(--base-three));
    filter: blur(200px);
    z-index: -1;
    width: 200px;
    height: 324px;
    transform: rotate(-76.493deg);
}
.shape-four-bg {
    position: absolute;
    bottom: -172px;
    right: -50px;
    background-color: hsl(var(--base-three));
    filter: blur(300px);
    z-index: -1;
    width: 400px;
    height: 100%;
    opacity: .5;
}
.banner-shape {
    @include xsm-screen {
        display: none;
    }
    &__one {
        position: absolute;
        top: 70px;
        left: 250px;
        animation: move 4s linear infinite;
        @include xl-screen {
            top: 40px;
            left: 120px;
        }
        @include md-screen {
            left: 90px;
            max-width: 60px;
        }
        @include sm-screen {
            left: 90px;
            max-width: 45px;
            top: 70px;
        }
    }
    &__two {
        position: absolute;
        top: 170px;
        right: 170px;
        animation: move 3s linear infinite;
       @include xl-screen {
            top: 140px;
            right: 120px;
        }
        @include md-screen {
            top: 100px;
            right: 80px;
            max-width: 60px;
        }
        @include sm-screen {
            right: 40px;
            max-width: 40px;
        }
    }
    &__three {
        position: absolute;
        bottom: 240px;
        right: 310px;
        animation: move 5s linear infinite;
        @include xl-screen {
            bottom: 140px;
            right: 220px;
        }
        @include md-screen {
            bottom: 180px;
            right: 120px;
            max-width: 60px;
        }
        @include sm-screen {
            right: 90px;
            max-width: 40px;
            bottom: 140px;
        }
    }
    &__four {
        position: absolute;
        bottom: 256px;
        left: 340px;
        animation: move 7s linear infinite;
       @include xl-screen {
            bottom: 140px;
            left: 120px;
        }
        @include md-screen {
            bottom: 180px;
            left: 120px;
            max-width: 60px;
        }
        @include sm-screen {
            bottom: 140px;
            left: 100px;
            max-width: 40px;
        }
    }
}
@keyframes move {
    0% {
      transform: translateY(100%);
    }
    50% {
      transform: translateY(70%);
    }
    100% {
      transform: translateY(100%);
    }

  }