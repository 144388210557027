/* =============================== Brand Section Css Start ======================= */
.trading-slider {
  background: hsl(var(--section-bg));
  padding: 16px 0;
    .slick-slide {
     margin-right: 20px;
    }
  }


.trading {
  margin-top: -70px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  &__desc {
    text-align: center;
    margin-bottom: 20px;
  }
}
.trading-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: max-content;
  &::after {
    position: absolute;
    content: "";
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 30px;
    border-radius: 8px;
    background: hsl(var(--body-color)/.5);
    @media (max-width:1750px) {
      display: none;
    }
  }
}
.trading-item {
  &__content {
    display: flex;
    align-items: center;
  }
  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  &__title {
    margin-right: 22px;
    font-weight: 600;
    padding-right: 11px;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      right: -10px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: hsl(var(--body-color)/.7);
      transform: translateY(-50%);
    }
  }
  &__number {
    margin-right: 10px;
  }
  &__rate {
    color: hsl(var(--base-three));
  }
}

/* =============================== Brand Section Css End ======================= */