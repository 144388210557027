/* ================================= Button Css Start =========================== */
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: none;
    background-color: none;
    border-color: none;
}

.pill {
    border-radius: 40px !important;
}

.btn {
    color: hsl(var(--white)) !important;
    font-weight: 500;
    padding: 19px 25px;
    border-radius: 8px;
    position: relative;
    z-index: 1;
    border: 1px solid transparent;
    font-family: var(--body-font);
    @extend .fs-16; 
    line-height: 1;
    @include sm-screen {
        padding: 14px 25px;
        @include fs-14; 
    }
    &:hover, &:focus, &:focus-visible {
        box-shadow: none !important;
    }

    &--lg {
        padding: 20px 48px;
        @include md-screen {
            padding: 18px 30px;
        }
        @include sm-screen {
            padding: 16px 25px;
        }
    }
    &--sm {
        padding: 13px 23px;
        @include fs-13;
        @include sm-screen {
            padding: 8px 16px;
        }
    }
    &--icon {
        width: 35px;
        height: 35px;
        line-height: 35px;
        @extend .fs-15; 
        padding: 0;
    }
    .icon {
        margin-right: 5px;
    }
    // ============= Different Color Button Start ==================
    &--base {
        background: var(--base-gradient);
        position: relative;
        z-index: 1;
        overflow: hidden;
        border: none;
        &::before {
            position: absolute;
            left: -100%;
            top: 0;
            height: 100%;
            width: 100%;
            background: var(--base-gradient-reverse);
            content: "";
            z-index: -1;
            border-radius: 3px;
            transition: .3s ease-in-out;
        }
        &:hover::before {
            left: 0%;
        }
        &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0%;
            width: 100%;
            height: 100%;
            background: var(--base-gradient);
            transition: all ease 0.3s;
            z-index: -1;
        }
        &:hover::after {
            left: 100%;
        }
    }
    &--disabled {
        background: hsl(var(--white)/.1) !important;
        cursor: initial !important;
        color: hsl(var(--white)) !important;
        &::after, &::before {
            display: none;
        }
        &:hover {
            &.btn--base {
                background: hsl(var(--white)/.2) !important;
            }
        }
    }

    &--primary {
        background-color: hsl(var(--primary)) !important;
        &:hover, &:focus &:focus-visible {
            background-color: hsl(var(--primary-d-200)) !important;
            border: 1px solid hsl(var(--primary-d-200)) !important;
        }
    }
    &-outline--primary {
        background-color: transparent !important;
        border: 1px solid hsl(var(--primary)) !important;
        color: hsl(var(--primary)) !important;
        &:hover, &:focus &:focus-visible{
            background-color: hsl(var(--primary)) !important;
            color: hsl(var(--white)) !important;
        }
    }
    &--secondary {
        background-color: hsl(var(--secondary)) !important;
        &:hover, &:focus &:focus-visible {
            background-color: hsl(var(--secondary-d-200)) !important;
            border: 1px solid hsl(var(--secondary-d-200)) !important;
        }
    }
    &-outline--secondary {
        background-color: transparent !important;
        border: 1px solid hsl(var(--secondary)) !important;
        color: hsl(var(--secondary)) !important;
        &:hover, &:focus &:focus-visible{
            background-color: hsl(var(--secondary)) !important;
            color: hsl(var(--white)) !important;
        }
    }
    &--success {
        background-color: hsl(var(--success)) !important;
        &:hover, &:focus &:focus-visible {
            background-color: hsl(var(--success-d-200)) !important;
            border: 1px solid hsl(var(--success-d-200)) !important;
        }
    }
    &-outline--success {
        background-color: transparent !important;
        border: 1px solid hsl(var(--success)) !important;
        color: hsl(var(--success)) !important;
        &:hover, &:focus &:focus-visible{
            background-color: hsl(var(--success)) !important;
            color: hsl(var(--white)) !important;
        }
    }
    &--danger {
        background-color: hsl(var(--danger)) !important;
        &:hover, &:focus &:focus-visible {
            background-color: hsl(var(--danger-d-200)) !important;
            border: 1px solid hsl(var(--danger-d-200)) !important;
        }
    }
    &-outline--danger {
        background-color: transparent !important;
        border: 1px solid hsl(var(--danger)) !important;
        color: hsl(var(--danger)) !important;
        &:hover, &:focus &:focus-visible{
            background-color: hsl(var(--danger)) !important;
            color: hsl(var(--white)) !important;
        }
    }
    &--warning {
        background-color: hsl(var(--warning)) !important;
        &:hover, &:focus &:focus-visible {
            background-color: hsl(var(--warning-d-200)) !important;
            border: 1px solid hsl(var(--warning-d-200)) !important;
        }
    }
    &-outline--warning {
        background-color: transparent !important;
        border: 1px solid hsl(var(--warning)) !important;
        color: hsl(var(--warning)) !important;
        &:hover, &:focus &:focus-visible{
            background-color: hsl(var(--warning)) !important;
            color: hsl(var(--white)) !important;
        }
    }
    &--info {
        background-color: hsl(var(--info)) !important;
        &:hover, &:focus &:focus-visible {
            background-color: hsl(var(--info-d-200)) !important;
            border: 1px solid hsl(var(--info-d-200)) !important;
        }
    }
    &-outline--info {
        background-color: transparent !important;
        border: 1px solid hsl(var(--info)) !important;
        color: hsl(var(--info)) !important;
        &:hover, &:focus &:focus-visible{
            background-color: hsl(var(--info)) !important;
            color: hsl(var(--white)) !important;
        }
    }
    &--dark {
        background-color: hsl(var(--dark)) !important;
        &:hover, &:focus &:focus-visible {
            background-color: hsl(var(--dark-d-200)) !important;
            border: 1px solid hsl(var(--dark-d-200)) !important;
        }
    }
    &-outline--dark {
        background-color: transparent !important;
        border: 1px solid hsl(var(--dark)) !important;
        color: hsl(var(--dark)) !important;
        &:hover, &:focus &:focus-visible{
            background-color: hsl(var(--dark)) !important;
            color: hsl(var(--white)) !important;
        }
    }
}
/* ================================= Button Css End =========================== */
.btn-outline--base {
    background: var(--base-gradient) !important;
    border: transparent !important;
    color: hsl(var(--white)) !important;
    z-index: 2;
    position: relative;
    overflow: hidden;
    &::after {
        position: absolute;
        content: "";
        top: 1px;
        left: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: hsl(var(--body-bg)) !important;
        z-index: -1;
        border-radius: 8px;
        transition: .3s;
    }
    &::before {
        position: absolute;
        content: "";
        top: 0px;
        left: 0px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        z-index: -1;
        border-radius: 8px;
        background: var(--base-gradient-reverse) !important;
        background-size: 50% 100%; 
        animation: gradient 7s linear 0s infinite;
        transition: 1s linear;
    }
    @keyframes gradient {
        100% {
            // transform: translateX(-50%);
            transform: rotate(1turn);
        }
    }
    &:hover, &:focus &:focus-visible{
        background-color: hsl(var(--base)) !important;
        color: hsl(var(--white)) !important;
        &::after {
            background: var(--base-gradient) !important;
        }
    }
}



// .btn.btn-outline--base {
//     z-index: 2;
//     overflow: hidden;
//     position: relative;
//     z-index: 2;
//     align-items: center;
//     background: #000 !important;
//     border-radius: 3px;
//     display: block;
//     justify-content: center;
//     box-sizing: border-box;
//     height: 100%;
//     &::before {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 200%;
//         height: 100%;
//         background: var(--base-gradient) !important;
//         background-size: 50% 100%; 
//        animation: rainbow-btn .75s linear infinite
//     }
// }

// @keyframes rainbow-btn {
//     to {
//         transform: translateX(-50%)
//     }
// }