/* ================================= Form Css Start =========================== */
/* Form Label */
.form--label {
    margin-bottom: 10px;
    @extend .fs-16; 
    color: hsl(var(--white)/.6);
    font-weight: 400;
}

.form-group {
    margin-bottom: 1rem;
}
/* Form Select */   
.select {
    color: hsl(var(--black) / .6) !important;
    &:focus {
        border-color: hsl(var(--base));
        color: hsl(var(--black)) !important;
    }
    option {
        background-color: hsl(var(--black));
        color: hsl(var(--white));
    }
}
/* Form Select End */

/* Form Control Start */
.form--control {
    border-radius: 12px;
    font-weight: 400;
    outline: none;
    width: 100%;
    padding: 17px 24px;
    background-color: hsl(var(--body-bg)) !important; 
    border: 1px solid hsl(var(--base) / .14);
    color: hsl(var(--white)); 
    line-height: 1;
    &::placeholder {
        color: hsl(var(--white) / .6);
        @extend .fs-14; 
    }
    &:focus {
        border-color: hsl(var(--base)); 
        box-shadow: none;
    }
    &:disabled, &[readonly] {
        background-color: hsl(var(--black)/.2); 
        opacity: 1;
        border: 0;
    }
    &[type="password"] {
        color: hsl(var(--white)/.5);
        &:focus {
            color: hsl(var(--white)); 
        }
    }
    &[type="file"] {
        line-height: 50px;
        padding: 0;
        position: relative;
        &::file-selector-button {
            border: 1px solid hsl(var(--black) / .08);
            padding: 4px 6px;
            border-radius: .2em;
            background-color: hsl(var(--base)) !important;
            transition: .2s linear;
            line-height: 25px;
            position: relative;
            margin-left: 15px;
            color: hsl(var(--body-color)) !important;
            &:hover {
                background-color: hsl(var(--base));
                border: 1px solid hsl(var(--base));
                color: hsl(var(--black));
            }
        }
    }
}
/* Form Control End */

// Message Field Css
textarea.form--control {
    height: 130px;
}
/* Autofill Css */
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active, 
textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, textarea:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-text-fill-color: hsl(var(--white)) !important;
    caret-color: hsl(var(--white));
}
/* Autofill Css End */

/* input group */
.input--group {
    position: relative;
}
/* Show Hide Password */
input#your-password, input#confirm-password {
    padding-right: 50px;
}
.password-show-hide {
    position: absolute;
    right: 20px;
    z-index: 5;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    color: hsl(var(--white)/.4);
}
/* --------------- Number Arrow None --------------------- */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type=number]{
    -moz-appearance: textfield;
}

/* Custom Checkbox Design */
.form--check {
    @extend .flex-wrap; 
    margin-bottom: 12px;
    a {
        display: inline;
    }
    .form-check-input {
        box-shadow: none;
        background: var(--base-gradient);
        box-shadow: none !important;
        border: 0;
        position: relative;
        border-radius: 4px;
        width: 22px;
        height: 22px;
        border: 1px solid hsl(var(--black)/.2);
        cursor: pointer;
        &::after {
            position: absolute;
            content: '';
            top: 1px;
            left: 1px;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            background: hsl(var(--section-bg-two));
            border-radius: 4px;
        }
        &:checked {
            box-shadow: none;
            &[type=checkbox] {
                background-image:none;
                }
            &::before {
                position: absolute;
                content: "\f00c";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                color: hsl(var(--white));
                top: 0;
                left: 0;
                z-index: 1;
                background: var(--base-gradient);
                width: 22px;
                height: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
            }
        }
    }
    .form-check-label {
        font-weight: 600;
        width: calc(100% - 22px);
        padding-left: 12px;
        cursor: pointer;
        color: hsl(var(--white)/.6);
        @extend .fs-14; 
    }
    label {
        @include xsm-screen {
            @include fs-15; 
        }
    }
    a {
        @include xsm-screen {
            @include fs-15; 
        }
    }
}
/* Custom Radio Design */
.form--radio {
    @extend .flex-wrap; 
    .form-check-input {
        box-shadow: none;
        border: 1px solid hsl(var(--black)/.2);
        position: relative;
        background-color: transparent;
        cursor: pointer;
        width: 16px;
        height: 16px;
        &:active {
            filter: brightness(100%);
        }
        &:checked {
            background-color: transparent;
            border-color: hsl(var(--base));
            &[type=radio] {
                background-image:none ;
                }
            &::before {
                position: absolute;
                content: "";
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                width: 7px;
                height: 7px;
                background-color: hsl(var(--base));
                border-radius: 50%;
                z-index: 999;
            }
        } 
    }
    .form-check-label {
        font-weight: 600;
        width: calc(100% - 16px);
        padding-left: 12px;
        cursor: pointer;
        @extend .fs-14; 
    }
}
/*  Custom Switch Design */
.form--switch {
    .form-check-input {
        border-radius: 3px;
        background-image: none;
        position: relative;
        box-shadow: none;
        border: 0;
        background-color: hsl(var(--black)/.2) !important;
        padding: 10px !important;
        margin-left: 0;
        margin-bottom: 5px;
        border-radius: 40px;
        width: 70px;
        height: 38px;
        cursor: pointer;
        &:focus {
            border-radius: 40px;
            background-image: none;
            position: relative;
            box-shadow: none;
            border: 0;
        }
        &::before {
            position: absolute;
            content: "";
            width: 28px;
            height: 28px;
            background-color: hsl(var(--white));
            top: 50%;
            transform: translateY(-50%);
            border-radius: 2px;
            left: 5px;
            border-radius: 50%;
            transition: 0.2s linear;
        }
        &:checked {
            background-color: hsl(var(--base)) !important;
            &::before {
                left: calc(100% - 33px);
                background-color: hsl(var(--white)) !important;
            }
        }
        &:checked[type=checkbox] {
            background-image: none;
        }
    }
    .form-check-label {
        width: calc(100% - 14px);
        padding-left: 5px;
        cursor: pointer;
    }
}
/*  Custom Switch End Design */
/* ================================= Form Css End =========================== */